import React, { useState } from 'react';
import { ThemeType } from 'types/baseTypes';
import { ChevronTopIcon } from '../../Icons';
import Button from '../Button';
import s from './AccordionSwitcher.module.scss';

const getColor = (position: string, target: string) => {
  if (target === position) return 'blue';
  return 'grey';
};

interface AccordionSwitcherProps {
  increaseTitle?: string;
  decreaseTitle?: string;
  className?: string;
  theme?: ThemeType;
  fullWidth?: boolean;
}

const AccordionSwitcher = ({
  increaseTitle = '',
  decreaseTitle = '',
  className,
  theme = 'default',
  fullWidth = false,
}: AccordionSwitcherProps) => {
  const [target, setTarget] = useState('increase');

  const activeLeftBtn = target === 'increase';
  const activeRightBtn = target === 'decrease';

  const handleChangeTarget = (position: string): void => {
    setTarget(position);
  };

  return (
    <div
      className={`${s.wrapper} ${activeLeftBtn ? s.leftActive : ''} ${
        activeRightBtn ? s.rightActive : ''
      } ${className}`}>
      <Button
        align="start"
        brDisabled="right"
        color={getColor('increase', target)}
        fullWidth={fullWidth}
        onClick={() => handleChangeTarget('increase')}
        size="mmd"
        startIcon={<ChevronTopIcon className={target === 'decrease' ? s['not-active'] : ''} />}
        theme={theme}
        variant="ghost">
        {increaseTitle}
      </Button>
      <Button
        align="start"
        brDisabled="left"
        color={getColor('decrease', target)}
        fullWidth={fullWidth}
        onClick={() => handleChangeTarget('decrease')}
        size="mmd"
        startIcon={
          <ChevronTopIcon
            className={`${s.rotate} ${target === 'increase' ? s['not-active'] : ''}`}
          />
        }
        theme={theme}
        variant="ghost">
        {decreaseTitle}
      </Button>
    </div>
  );
};

export default AccordionSwitcher;
