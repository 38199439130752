import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { ThemeType } from 'types/baseTypes';

import Processing from '../../Simple/Processing';
import Typography from '../../Simple/Typography';
import s from './QuestionsBlock.module.scss';

interface QuestionsBlockProps {
  theme?: ThemeType;
  title?: string;
  subTitle?: string;
  countUp?: string | number;
  countDown?: string | number;
  smallBlock?: boolean;
  onClickUp?: () => void;
  onClickDown?: () => void;
  targetValue?: number;
}

const QuestionsBlock = ({
  smallBlock = false,
  targetValue,
  theme = 'default',
  title = '',
  subTitle = '',
  countUp = '100',
  countDown = '100',
  onClickUp = () => {},
  onClickDown = () => {},
}: QuestionsBlockProps) => {
  const isOpenSideBar = useSelector((state: RootState) => state.app.isOpenSideBar);

  const visibleDivider = () => {
    if (smallBlock) {
      return null;
    }
    if (isOpenSideBar) {
      return <div className={s.horizontalDivider} />;
    }

    return <div className={s.divider} />;
  };
  return (
    <div className={classNames(s.wrapper, s[theme], { [s.openSideBar]: !isOpenSideBar })}>
      <div className={s.titleBlock}>
        <Typography color="grey">{title}</Typography>
        <Typography color={theme === 'dark' ? 'white' : 'dark'} variant="h1">
          {subTitle}
        </Typography>
      </div>
      {visibleDivider()}
      <div className={s.div} />
      {!smallBlock && (
        <div className={s.processingBlock}>
          <div className={s.processItem} onClick={onClickUp}>
            <Processing
              count={countUp}
              dynamicStatus="up"
              theme={theme}
              title={`Выше ${targetValue}`}
            />
          </div>
          {isOpenSideBar && !smallBlock && <div className={s.divider} onClick={onClickDown} />}
          <div className={s.processItem}>
            <Processing
              count={countDown}
              dynamicStatus="down"
              theme={theme}
              title={`Ниже ${targetValue}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsBlock;
