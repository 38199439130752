import React from 'react';
import { Children } from 'types/children';
import Typography from '../../Simple/Typography';
import s from './TransactionsWrapper.module.scss';

interface TransactionsWrapperProps {
  children?: Children;
  date?: string;
}

const TransactionsWrapper = ({ children, date }: TransactionsWrapperProps) => (
  <div className={s.transactionBlock}>
    <Typography color="grey" variant="ssm">
      {date}
    </Typography>
    {children}
  </div>
);

export default TransactionsWrapper;
