import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const LogoIcon = ({ height = '35', width = '142', color = '#101319', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 142 35"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_462_4149)">
      <path
        d="M13.8231 0.425181L0.839358 7.63135C0.167872 8.00402 0 8.72319 0 9.03614V11.2994C0 12.0279 0.559571 11.8632 0.839358 11.6897C5.04488 9.25291 13.6133 4.29102 14.2428 3.93721C14.8723 3.58341 15.5543 3.78979 15.8166 3.93721C19.279 5.90568 26.3348 9.92065 26.8594 10.2328C27.384 10.545 27.5326 11.2301 27.5414 11.5336V15.618C27.5414 16.2631 26.9643 16.7192 26.6758 16.8667C25.7752 17.3176 23.7957 18.3079 23.0823 18.6617C22.3688 19.0155 21.596 18.8091 21.2987 18.6617C17.3642 16.5545 9.36409 12.2828 8.83946 12.0539C8.31491 11.825 7.81652 11.9585 7.6329 12.0539L0.813123 16.1643C0.183609 16.4972 0.00874329 17.3089 0 17.6732V25.1915C0 26.1072 0.647004 26.5789 0.970505 26.7003L14.6363 34.4789C15.4127 34.9575 16.2014 34.6783 16.4986 34.4789C20.6691 32.1549 29.1886 27.4131 29.9021 27.0385C30.6155 26.6639 30.8114 25.8245 30.8201 25.4516V23.4745C30.8201 22.6628 30.2081 22.8241 29.9021 23.0062C26.0113 25.2522 17.978 29.9054 16.9708 30.5506C15.9635 31.1957 14.9947 30.8194 14.6363 30.5506C11.3663 28.7122 4.66892 24.9573 4.0394 24.6452C3.40989 24.333 3.23502 23.5265 3.22628 23.1623V19.5202C3.22628 18.7085 3.76836 18.1587 4.0394 17.9853C4.79133 17.6124 6.66764 16.4519 7.6329 15.9732C8.59814 15.4946 9.33784 16.1293 9.6788 16.2854C13.3335 18.2105 20.7688 22.1581 21.5872 22.616C22.4056 23.0739 23.2222 22.8067 23.5282 22.616L29.9021 18.7397C30.6784 18.49 30.8376 17.4737 30.8201 16.9967V9.94667C30.8201 8.71878 30.2081 8.20367 29.9021 8.09959L29.1151 7.63135C25.4692 5.55014 17.8678 1.19523 16.6297 0.425181C15.3917 -0.344863 14.2428 0.104329 13.8231 0.425181Z"
        fill="#00D355"
      />
      <path
        d="M22.6589 11.487L16.5289 8.11058C15.7535 7.68343 15.1579 7.8633 14.9571 8.00665C14.6863 8.22311 14.014 8.76508 13.49 9.20144C12.9661 9.63772 13.3416 10.0758 13.5948 10.2403C16.0399 11.5736 21.0505 14.3077 21.5325 14.5778C22.0145 14.8479 22.4319 14.8808 22.5803 14.8635C23.5496 14.8116 23.5234 13.5129 23.5234 12.7337C23.5234 12.1104 22.9471 11.6428 22.6589 11.487Z"
        fill="#00D355"
      />
      <path
        d="M8.32797 23.2103L14.458 26.5868C15.2334 27.0139 15.829 26.8341 16.0298 26.6907C16.3005 26.4743 16.9729 25.9323 17.4969 25.496C18.0208 25.0596 17.6453 24.6215 17.392 24.457C14.947 23.1237 9.93641 20.3897 9.45438 20.1196C8.97242 19.8494 8.55502 19.8166 8.4065 19.8339C7.43725 19.8858 7.46345 21.1844 7.46345 21.9636C7.46345 22.587 8.03975 23.0545 8.32797 23.2103Z"
        fill="#00D355"
      />
      <path
        d="M43.5537 23.7166V12.9307V11.2109H53.4802C54.459 11.2109 55.2405 11.3171 55.8249 11.5294C56.4238 11.7276 56.8694 12.0035 57.1616 12.3574C57.4683 12.6971 57.6656 13.1006 57.7532 13.5677C57.8555 14.0206 57.9066 14.5019 57.9066 15.0114V16.6887C57.9066 17.3682 57.7897 17.998 57.556 18.5784C57.3368 19.1446 56.9205 19.6046 56.307 19.9585C55.7081 20.2982 54.8388 20.4681 53.6993 20.4681H52.1874C51.2671 20.4681 50.3903 20.5105 49.5869 20.4681C48.6448 20.4681 48.2211 20.4964 47.8268 20.4681V23.7166H43.5537ZM47.8268 17.5805H52.3627C52.8447 17.5805 53.1808 17.4885 53.3707 17.3045C53.5606 17.1205 53.6555 16.8233 53.6555 16.4128V15.3299C53.6555 14.9194 53.5679 14.6222 53.3925 14.4382C53.2173 14.24 52.8813 14.1409 52.3846 14.1409H47.8268V17.5805Z"
        fill={color}
      />
      <path
        d="M43.5537 23.7166V12.9307V11.2109H53.4802C54.459 11.2109 55.2405 11.3171 55.8249 11.5294C56.4238 11.7276 56.8694 12.0035 57.1616 12.3574C57.4683 12.6971 57.6656 13.1006 57.7532 13.5677C57.8555 14.0206 57.9066 14.5019 57.9066 15.0114V16.6887C57.9066 17.3682 57.7897 17.998 57.556 18.5784C57.3368 19.1446 56.9205 19.6046 56.307 19.9585C55.7081 20.2982 54.8388 20.4681 53.6993 20.4681H52.1874C51.2671 20.4681 50.3903 20.5105 49.5869 20.4681C48.6448 20.4681 48.2211 20.4964 47.8268 20.4681V23.7166H43.5537ZM47.8268 17.5805H52.3627C52.8447 17.5805 53.1808 17.4885 53.3707 17.3045C53.5606 17.1205 53.6555 16.8233 53.6555 16.4128V15.3299C53.6555 14.9194 53.5679 14.6222 53.3925 14.4382C53.2173 14.24 52.8813 14.1409 52.3846 14.1409H47.8268V17.5805Z"
        fill={color}
      />
      <path
        d="M60.0908 23.7161V21.2319C60.31 21.0904 60.6386 20.8922 61.0769 20.6374C61.5152 20.3685 62.0192 20.0641 62.5889 19.7244C63.1586 19.3847 63.743 19.0309 64.3419 18.6628C64.9408 18.2948 65.5106 17.9409 66.0511 17.6013C66.5916 17.2615 67.0518 16.9572 67.4316 16.6882C67.9137 16.3627 68.2424 16.0725 68.4177 15.8177C68.593 15.563 68.6807 15.294 68.6807 15.0109V14.7137C68.6807 14.4306 68.593 14.2324 68.4177 14.1191C68.257 13.9918 67.9356 13.9281 67.4535 13.9281H66.3579C64.9263 13.9281 63.8041 13.9281 62.7763 13.9281C61.7485 13.9281 61.0633 13.9281 60.31 13.9281V10.998C60.7208 10.998 60.5494 10.998 61.2347 10.998C62.0055 10.998 62.7787 10.998 64.061 10.998C65.1744 10.998 66.4893 10.998 67.8918 10.998H68.0452C69.3015 10.998 70.2876 11.1396 71.0034 11.4227C71.7192 11.6917 72.2232 12.0738 72.5154 12.5692C72.8076 13.0646 72.9536 13.645 72.9536 14.3103V14.7561C72.9536 15.7469 72.6541 16.5538 72.0552 17.1766C71.4563 17.7994 70.6893 18.3726 69.7543 18.8964C69.0239 19.3068 68.3666 19.6536 67.7822 19.9367C67.2125 20.2199 66.745 20.4604 66.3798 20.6587V20.8285H73.611V23.7161H60.0908Z"
        fill={color}
      />
      <path
        d="M60.0908 23.7161V21.2319C60.31 21.0904 60.6386 20.8922 61.0769 20.6374C61.5152 20.3685 62.0192 20.0641 62.5889 19.7244C63.1586 19.3847 63.743 19.0309 64.3419 18.6628C64.9408 18.2948 65.5106 17.9409 66.0511 17.6013C66.5916 17.2615 67.0518 16.9572 67.4316 16.6882C67.9137 16.3627 68.2424 16.0725 68.4177 15.8177C68.593 15.563 68.6807 15.294 68.6807 15.0109V14.7137C68.6807 14.4306 68.593 14.2324 68.4177 14.1191C68.257 13.9918 67.9356 13.9281 67.4535 13.9281H66.3579C64.9263 13.9281 63.8041 13.9281 62.7763 13.9281C61.7485 13.9281 61.0633 13.9281 60.31 13.9281V10.998C60.7208 10.998 60.5494 10.998 61.2347 10.998C62.0055 10.998 62.7787 10.998 64.061 10.998C65.1744 10.998 66.4893 10.998 67.8918 10.998H68.0452C69.3015 10.998 70.2876 11.1396 71.0034 11.4227C71.7192 11.6917 72.2232 12.0738 72.5154 12.5692C72.8076 13.0646 72.9536 13.645 72.9536 14.3103V14.7561C72.9536 15.7469 72.6541 16.5538 72.0552 17.1766C71.4563 17.7994 70.6893 18.3726 69.7543 18.8964C69.0239 19.3068 68.3666 19.6536 67.7822 19.9367C67.2125 20.2199 66.745 20.4604 66.3798 20.6587V20.8285H73.611V23.7161H60.0908Z"
        fill={color}
      />
      <path
        d="M76.0161 23.7166V12.9307V11.2109H85.9429C86.9216 11.2109 87.7029 11.3171 88.2871 11.5294C88.8865 11.7276 89.3317 12.0035 89.6242 12.3574C89.9304 12.6971 90.1277 13.1006 90.216 13.5677C90.318 14.0206 90.3687 14.5019 90.3687 15.0114V16.6887C90.3687 17.3682 90.252 17.998 90.0187 18.5784C89.7992 19.1446 89.3831 19.6046 88.7691 19.9585C88.1704 20.2982 87.3015 20.4681 86.1617 20.4681H84.6496C83.7292 20.4681 82.9033 20.4681 81.9615 20.4681C81.1901 20.4681 80.7096 20.4681 80.289 20.4681V23.7166H76.0161ZM80.289 17.5805H84.8253C85.3074 17.5805 85.6428 17.4885 85.8331 17.3045C86.0227 17.1205 86.1179 16.8233 86.1179 16.4128V15.3299C86.1179 14.9194 86.0304 14.6222 85.8546 14.4382C85.6796 14.24 85.3434 14.1409 84.8468 14.1409H80.289V17.5805Z"
        fill={color}
      />
      <path
        d="M76.0161 23.7166V12.9307V11.2109H85.9429C86.9216 11.2109 87.7029 11.3171 88.2871 11.5294C88.8865 11.7276 89.3317 12.0035 89.6242 12.3574C89.9304 12.6971 90.1277 13.1006 90.216 13.5677C90.318 14.0206 90.3687 14.5019 90.3687 15.0114V16.6887C90.3687 17.3682 90.252 17.998 90.0187 18.5784C89.7992 19.1446 89.3831 19.6046 88.7691 19.9585C88.1704 20.2982 87.3015 20.4681 86.1617 20.4681H84.6496C83.7292 20.4681 82.9033 20.4681 81.9615 20.4681C81.1901 20.4681 80.7096 20.4681 80.289 20.4681V23.7166H76.0161ZM80.289 17.5805H84.8253C85.3074 17.5805 85.6428 17.4885 85.8331 17.3045C86.0227 17.1205 86.1179 16.8233 86.1179 16.4128V15.3299C86.1179 14.9194 86.0304 14.6222 85.8546 14.4382C85.6796 14.24 85.3434 14.1409 84.8468 14.1409H80.289V17.5805Z"
        fill={color}
      />
      <path
        d="M93.4297 23.7166V11.2109H103.422C104.795 11.2109 105.796 11.4798 106.424 12.0178C107.052 12.5415 107.366 13.1784 107.366 13.9286V14.5655C107.366 15.1459 107.235 15.6272 106.972 16.0094C106.724 16.3774 106.453 16.6392 106.161 16.795C106.804 16.9931 107.315 17.3399 107.695 17.8353C108.075 18.3165 108.264 18.9535 108.264 19.7462V20.1283C108.264 20.553 108.214 20.9776 108.112 21.4023C108.009 21.8269 107.812 22.2162 107.52 22.57C107.242 22.9098 106.841 23.1858 106.315 23.3981C105.804 23.6104 105.131 23.7166 104.298 23.7166H93.4297ZM97.7027 16.0306H102.086C102.465 16.0306 102.728 15.9528 102.875 15.797C103.02 15.6413 103.093 15.429 103.093 15.1601V14.9477C103.093 14.6505 103.02 14.4382 102.875 14.3108C102.728 14.1834 102.399 14.1197 101.888 14.1197H97.7027V16.0306ZM97.7027 20.8078H102.984C103.364 20.8078 103.627 20.7441 103.773 20.6167C103.919 20.4751 103.992 20.2558 103.992 19.9585V19.5763C103.992 19.3074 103.897 19.1021 103.707 18.9606C103.517 18.8049 103.189 18.727 102.721 18.727H97.7027V20.8078Z"
        fill={color}
      />
      <path
        d="M93.4297 23.7166V11.2109H103.422C104.795 11.2109 105.796 11.4798 106.424 12.0178C107.052 12.5415 107.366 13.1784 107.366 13.9286V14.5655C107.366 15.1459 107.235 15.6272 106.972 16.0094C106.724 16.3774 106.453 16.6392 106.161 16.795C106.804 16.9931 107.315 17.3399 107.695 17.8353C108.075 18.3165 108.264 18.9535 108.264 19.7462V20.1283C108.264 20.553 108.214 20.9776 108.112 21.4023C108.009 21.8269 107.812 22.2162 107.52 22.57C107.242 22.9098 106.841 23.1858 106.315 23.3981C105.804 23.6104 105.131 23.7166 104.298 23.7166H93.4297ZM97.7027 16.0306H102.086C102.465 16.0306 102.728 15.9528 102.875 15.797C103.02 15.6413 103.093 15.429 103.093 15.1601V14.9477C103.093 14.6505 103.02 14.4382 102.875 14.3108C102.728 14.1834 102.399 14.1197 101.888 14.1197H97.7027V16.0306ZM97.7027 20.8078H102.984C103.364 20.8078 103.627 20.7441 103.773 20.6167C103.919 20.4751 103.992 20.2558 103.992 19.9585V19.5763C103.992 19.3074 103.897 19.1021 103.707 18.9606C103.517 18.8049 103.189 18.727 102.721 18.727H97.7027V20.8078Z"
        fill={color}
      />
      <path
        d="M111.341 23.7168V11.2324H125.409V14.1199H115.614V15.8822H122.867V18.8334H115.614V20.808H125.058L126.412 23.7168C126.155 23.7168 126.155 23.7168 125.727 23.7168C125.298 23.7168 124.708 23.7168 123.743 23.7168H111.341Z"
        fill={color}
      />
      <path
        d="M111.341 23.7168V11.2324H125.409V14.1199H115.614V15.8822H122.867V18.8334H115.614V20.808H125.058L126.412 23.7168C126.155 23.7168 126.155 23.7168 125.727 23.7168C125.298 23.7168 124.708 23.7168 123.743 23.7168H111.341Z"
        fill={color}
      />
      <path
        d="M132.469 23.7166V14.0984H127.253V11.2109H142V14.0984H136.719V23.7166H132.469Z"
        fill={color}
      />
      <path
        d="M132.469 23.7166V14.0984H127.253V11.2109H142V14.0984H136.719V23.7166H132.469Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_462_4149">
        <rect fill="white" height={height} width={width} />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;
