import classNames from 'classnames';

import s from './StatusTag.module.scss';

interface StatusTagProps {
  text: string;
  variant: 'dark-red';
}

const StatusTag = ({ text, variant }: StatusTagProps) => (
  <div className={classNames(s.statusTag, s[variant])}>
    <span className={s.statusText}>{text}</span>
  </div>
);

export default StatusTag;
