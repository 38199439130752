import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  if (keycloak.authenticated && keycloak.token) {
    return children;
  }

  return <div />;
};

export default ProtectedRoute;
