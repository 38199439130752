import { useEffect } from 'react';

import dayjs from 'dayjs';
import useTranslate from 'hooks/useTranslate.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { FreegameBetStatus, setBetStatus } from 'store/slices/freegameSlice';

import { BtcIcon, TetherIcon } from 'components/Icons';
import formatLeft from 'helpers/formatLeft';
import { ThemeType } from 'types/baseTypes';

import RowBet from '../RowBet';

interface BetStatusInfoProps {
  lockDate: string;
  expirationDate: string;
  theme: ThemeType;
  now: number;
  onExpire: () => void;
}

const BetStatusInfo = ({ lockDate, expirationDate, theme, now, onExpire }: BetStatusInfoProps) => {
  const dispatch = useDispatch();
  const acceptingBets = useTranslate('options.accepting-bets');
  const waitingResult = useTranslate('options.waiting-result');
  const dealClosed = useTranslate('options.deal-closed');
  const poolDstributed = useTranslate('options.pool-distributed');
  let pulseSubtitle = '';
  let pulseTitle = '';
  const pulseTime = '';
  let pulseVariant: 'open' | 'accepted' | 'closed' = 'open';
  const lockDateLeft = Math.floor((dayjs(`${lockDate}Z`).valueOf() - now) / 1000);
  const lang = useSelector((state: RootState) => state.app.lang);

  if (lockDateLeft > 0) {
    pulseTitle = acceptingBets;
    pulseSubtitle = formatLeft(lockDateLeft, lang);
  } else if (dayjs(`${expirationDate}Z`).valueOf() > now) {
    const expirationDateLeft = Math.floor((dayjs(`${expirationDate}Z`).valueOf() - now) / 1000);
    pulseVariant = 'accepted';
    pulseTitle = waitingResult;
    pulseSubtitle = formatLeft(expirationDateLeft, lang);
  } else {
    pulseVariant = 'closed';
    pulseTitle = dealClosed;
    pulseSubtitle = poolDstributed;
  }

  useEffect(() => {
    if (lockDateLeft > 0) {
      dispatch(setBetStatus(FreegameBetStatus.open));
    } else if (dayjs(`${expirationDate}Z`).valueOf() > now) {
      dispatch(setBetStatus(FreegameBetStatus.accepted));
    } else {
      dispatch(setBetStatus(FreegameBetStatus.closed));
    }
  }, [lockDateLeft, dispatch, expirationDate, now, onExpire]);

  const currencyPair = useTranslate('options.currency-pair');
  const priceBet = useTranslate('options.price-bet');

  return (
    <RowBet
      currencySubTitle={currencyPair}
      currencyTitle="BTC · USDT"
      doneSubTitle={priceBet}
      doneTitle="10 FreeBet"
      inIcon={<BtcIcon />}
      outIcon={<TetherIcon />}
      pulseSubtitle={pulseSubtitle}
      pulseTime={pulseTime}
      pulseTitle={pulseTitle}
      pulseVariant={pulseVariant}
      theme={theme}
    />
  );
};

export default BetStatusInfo;
