import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import LoadingCircleIcon from '../../Icons/LoadingCircleIcon';
import s from './Button.module.scss';

interface ButtonProps {
  children?: Children;
  onClick?: (e?: React.MouseEvent<HTMLDivElement> | MouseEvent) => void;
  variant?: 'contained' | 'outlined' | 'ghost';
  color?:
    | 'primary'
    | 'secondary'
    | 'grey'
    | 'blue'
    | 'error'
    | 'white'
    | 'yellow'
    | 'black-yellow'
    | 'dark-red';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  fullWidth?: boolean;
  makeBet?: boolean;
  align?: 'start' | 'center' | 'end';
  brDisabled?: 'left' | 'right' | 'none';
  size?: 'xl' | 'lg' | 'md' | 'mmd' | 'sm' | 's' | 'xs';
  disabled?: boolean;
  iconButton?: boolean;
  className?: string;
  reference?: React.RefObject<HTMLDivElement>;
  theme?: ThemeType;
  isLoading?: boolean;
  dataFor?: string;
  dataTip?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant = 'contained',
  color = 'primary',
  startIcon,
  endIcon,
  fullWidth = false,
  makeBet = false,
  align = 'center',
  brDisabled = '',
  size = '',
  disabled = false,
  iconButton = false,
  className = '',
  reference,
  theme = 'default',
  isLoading = false,
  dataFor,
  dataTip,
}) => {
  const classes = classNames(
    s.button,
    s[color],
    s[`${variant}-${color}`],
    s[`${variant}-${color}-${theme}`],
    align && s[`align-${align}`],
    s[size],
    className,
    s[theme],
    {
      [s.fullwidth]: fullWidth === true,
      [s.makeBet]: makeBet === true,
      [s['br-left-disabled']]: brDisabled === 'left',
      [s['br-right-disabled']]: brDisabled === 'right',
      [s['disabled-dark']]: disabled === true && theme === 'dark',
      [s['disabled-default']]: disabled === true && theme === 'default',
      [s['icon-button']]: iconButton === true,
      [s.loadDefault]: isLoading === true && theme === 'default',
      [s.loadDark]: isLoading === true && theme === 'dark',
    },
  );

  return (
    <div
      className={classes}
      data-for={dataFor}
      data-tip={dataTip}
      onClick={onClick}
      ref={reference}>
      {isLoading && <LoadingCircleIcon className={s.loader} />}
      {startIcon || null}
      {children || null}
      {endIcon || null}
    </div>
  );
};

export default Button;
