import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { AuctionBet } from 'types/auctionBet';

export enum FreegameMode {
  short = 'short',
  long = 'long',
}

export enum FreegameBetDuration {
  long = 3600,
  short = 300,
}

export enum FreegameBetStatus {
  open = 'open',
  accepted = 'accepted',
  closed = 'closed',
  unset = 'unset',
}

interface IFreegameState {
  mode: FreegameMode;
  bannerIsActive: boolean;
  activeBet: AuctionBet | null;
  betValue: string;
  currentRate: string;
  betStatus: FreegameBetStatus;
}

const activeMode: FreegameMode =
  (Cookies.get('activeMode') as FreegameMode | undefined) || FreegameMode.long;

const initialState: IFreegameState = {
  mode: activeMode,
  bannerIsActive: true,
  activeBet: null,
  betStatus: FreegameBetStatus.unset,
  currentRate: '',
  betValue: '',
};

export const freegame = createSlice({
  name: 'freegame',
  initialState,
  reducers: {
    setMode(state, action) {
      state.mode = action.payload;
    },
    setBanner(state, action) {
      state.bannerIsActive = action.payload;
    },
    setActiveBet(state, action: PayloadAction<AuctionBet>) {
      state.activeBet = action.payload;
    },
    setBetValue(state, action: PayloadAction<string>) {
      state.betValue = action.payload;
    },
    setBetStatus(state, action: PayloadAction<FreegameBetStatus>) {
      state.betStatus = action.payload;
    },
    setCurrentRate(state, action: PayloadAction<string>) {
      state.currentRate = action.payload;
    },
  },
});

export const { setMode, setBanner, setActiveBet, setBetValue, setBetStatus, setCurrentRate } =
  freegame.actions;

export default freegame.reducer;
