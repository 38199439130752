import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum Modals {
  login = 'login',
  register = 'register',
  forgotPass = 'forgotPass',
}

interface IModalState {
  activeModal: Modals | null;
}

const initialState: IModalState = {
  activeModal: null,
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<Modals>) {
      state.activeModal = action.payload;
    },
    closeModal(state) {
      state.activeModal = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
