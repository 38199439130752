import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useLazyGetUserDataQuery,
  useUpdatePasswordMutation,
  useUpdateUserDataMutation,
} from 'store/services/apiService';

import Button from 'components/Simple/Button';
import TextField from 'components/Simple/TextField';
import { ThemeType } from 'types/baseTypes';

import Toast from '../Toast/Toast';
import ToastContent from '../Toast/ToastContent';
import Translated from '../Translated';
import s from './EditProfile.module.scss';
import schema from './validation';

interface EditProfileProps {
  theme: ThemeType;
  username?: string;
  email?: string;
  rewardAddress?: string;
  socialNetworks: {
    discord: string;
    telegram: string;
    twitter: string;
  };
}

interface ProfileFormValues {
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  rewardAddress: string;
  socialNetworks: {
    discord: string;
    telegram: string;
    twitter: string;
  };
}

const EditProfile = ({
  theme,
  username,
  email,
  rewardAddress,
  socialNetworks,
}: EditProfileProps) => {
  const [updatePassword, { isLoading: isUpdatingPassword }] = useUpdatePasswordMutation();
  const [updateUserData, { isLoading: isUpdatingUserData }] = useUpdateUserDataMutation();
  const [getUserData] = useLazyGetUserDataQuery();

  const methods = useForm<ProfileFormValues>({
    defaultValues: {
      username: username || '',
      email: email || '',
      password: '',
      passwordConfirmation: '',
      rewardAddress: rewardAddress || '',
      socialNetworks: {
        discord: socialNetworks.discord || '',
        telegram: socialNetworks.telegram || '',
        twitter: socialNetworks.twitter || '',
      },
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields },
  } = methods;

  const settingSaved = useTranslate('freegame.setting-saved');
  const dateUpdated = useTranslate('freegame.date-updated');
  const eroorCreate = useTranslate('freegame.eroor-create');
  const eroorText = useTranslate('states.error');
  const newPassword = useTranslate('app.new-password');
  const wallet = useTranslate('app.wallet');
  const usernameLabel = useTranslate('app.username');
  const yourEmail = useTranslate('app.your-email');

  const onSubmit = async (data: ProfileFormValues) => {
    try {
      if (dirtyFields.password && data.password) {
        await updatePassword({ password: data.password }).unwrap();
      }
      if (dirtyFields.rewardAddress || dirtyFields.email || data.socialNetworks) {
        await updateUserData({
          username: data.username,
          rewardAddress: data.rewardAddress,
          email: data.email,
          socialNetworks: data.socialNetworks,
        }).unwrap();
      }
      toast(<ToastContent text={dateUpdated} title={settingSaved} />);
    } catch (error: any) {
      const errorText = error?.data?.title || '';
      toast(
        <ToastContent
          text={
            <div>
              <div>{eroorCreate}</div>
              <div>{errorText}</div>
            </div>
          }
          title={eroorText}
        />,
      );
    }

    getUserData(null);
  };

  const isProfileUpdating = isUpdatingPassword || isUpdatingUserData;

  useEffect(() => {
    if (rewardAddress) {
      setValue('rewardAddress', rewardAddress);
    }
  }, [rewardAddress, setValue]);

  return (
    <div className={classNames(s.edit, s[theme])}>
      <div className={s.container}>
        <h1 className={s.title}>
          <Translated id="header.burger.edit-profile" />
        </h1>
        <FormProvider {...methods}>
          <form className={s.form}>
            <section className={s.section}>
              <div className={s.sectionHead}>
                <div className={s.sectionNumber}>1</div>
                <div className={s.sectionTitle}>
                  <Translated id="freegame.main-data" />
                </div>
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="username"
                  render={({ field }) => (
                    <TextField
                      autocomplete="username"
                      label={usernameLabel}
                      readOnly
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      label={yourEmail}
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </section>
            <section className={s.section}>
              <div className={s.sectionHead}>
                <div className={s.sectionNumber}>2</div>
                <div className={s.sectionTitle}>
                  <Translated id="freegame.payment-details" />
                </div>
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="rewardAddress"
                  render={({ field }) => (
                    <TextField
                      autocomplete="on"
                      label={wallet}
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </section>
            <section className={s.section}>
              <div className={s.sectionHead}>
                <div className={s.sectionNumber}>3</div>
                <div className={s.sectionTitle}>
                  <Translated id="freegame.safety" />
                </div>
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <TextField
                      autocomplete="new-password"
                      label={newPassword}
                      setValue={field.onChange}
                      theme={theme}
                      type="password"
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  render={({ field }) => (
                    <TextField
                      autocomplete="new-password"
                      error={errors.passwordConfirmation?.message}
                      label={<Translated id="freegame.repeat-password" />}
                      setValue={field.onChange}
                      theme={theme}
                      type="password"
                      value={field.value}
                    />
                  )}
                />
              </div>
            </section>
            <section className={s.section}>
              <div className={s.sectionHead}>
                <div className={s.sectionNumber}>4</div>
                <div className={s.sectionTitle}>
                  <Translated id="freegame.social-networks" />
                </div>
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="socialNetworks.telegram"
                  render={({ field }) => (
                    <TextField
                      label="Telegram"
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="socialNetworks.twitter"
                  render={({ field }) => (
                    <TextField
                      label="Twitter"
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div className={s.field}>
                <Controller
                  control={control}
                  name="socialNetworks.discord"
                  render={({ field }) => (
                    <TextField
                      label="Discord"
                      setValue={field.onChange}
                      theme={theme}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </section>
            <Button
              className={s.submitButton}
              color="secondary"
              fullWidth
              isLoading={isProfileUpdating}
              onClick={() => handleSubmit(onSubmit)()}
              size="xl"
              theme={theme}>
              <Translated id="freegame.save-settings" />
            </Button>
          </form>
        </FormProvider>
        <Toast />
      </div>
    </div>
  );
};

export default EditProfile;
