import { useTitle } from 'hooks/useTitle.hook';

import ChartRadioGroup from 'components/Simple/ChartRadioGroup';

import s from './chart1.module.scss';

export interface IChartHeader {
  direction: string;
  current: string;
  onChange: (val: string | number) => void;
}

const ChartHeader: React.FC<IChartHeader> = ({ direction, current, onChange }) => {
  useTitle(`${current} | BTC · USDT`);
  return (
    <div className={s.chart_header}>
      <div
        className={s.rate}
        style={{
          color: direction === 'up' ? '#2AE553' : '#FA3E2C',
        }}>
        ${current}
      </div>

      <div>
        <ChartRadioGroup onChange={onChange} />
      </div>
    </div>
  );
};
export default ChartHeader;
