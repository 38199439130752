import dayjs from 'dayjs';
import { FreegameBetDuration } from 'store/slices/freegameSlice';

import { IJoin } from 'types/join';
import { JoinStatus } from 'types/statuses';

import s from '../../Simple/MyDealCard/MyDealcard.module.scss';

export const wonStatuses = [JoinStatus.WON, JoinStatus.PRIZE_TAKEN];

const mapJoinsToPortfolio = (
  data: IJoin[],
  lang: string,
  period: FreegameBetDuration,
  attepmtsLabel: string,
) =>
  data
    .sort((a) => {
      const isWon = wonStatuses.indexOf(a.status) > -1;
      if (isWon) {
        return -1;
      }
      return 1;
    })
    .reduce((acc, join) => {
      const formattedDate = dayjs(`${join.createdDate}Z`)
        .locale(lang.toLocaleLowerCase())
        .format('D MMMM, HH:mm');
      const isWon = wonStatuses.indexOf(join.status) > -1;
      acc.push({
        join,
        tableData: [
          <div>
            <span className="tableValue">${join.targetValue}</span>
            {isWon && <div className={s.winBadge}>Win</div>}
          </div>,
          <div className="tableValue">
            {period === FreegameBetDuration.short
              ? `${join.joinAmount} FreeBet`
              : `1 ${attepmtsLabel}`}
          </div>,
          <div className="tableValue">{formattedDate}</div>,
        ],
      });
      return acc;
    }, [] as { join: IJoin; tableData: string[] | JSX.Element[] }[]);

export default mapJoinsToPortfolio;
