import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User, UserData } from 'types/user';

interface IUserSliceState {
  user: User | null;
  balance: number | null;
  paymentData: UserData | null;
}

const initialState: IUserSliceState = {
  user: null,
  paymentData: null,
  balance: null,
};

export const userSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setBalance(state, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
    setPaymentData(state, action: PayloadAction<UserData>) {
      state.paymentData = action.payload;
    },
  },
});

export const { setUser, setBalance, setPaymentData } = userSlice.actions;

export default userSlice.reducer;
