import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import { SearchIcon } from '../../Icons';
import s from './TextField.module.scss';

export interface TextFieldProps {
  theme?: ThemeType;
  label?: string | JSX.Element;
  isSearch?: boolean;
  placeholder?: string;
  value?: string;
  setValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  autocomplete?: string;
  background?: string;
  border?: string;
  className?: string;
  type?: string;
  error?: string;
  icon?: JSX.Element;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const TextField = ({
  theme = 'default',
  label = '',
  isSearch = false,
  placeholder = '',
  setValue,
  readOnly = false,
  background,
  border,
  type = 'text',
  className,
  error,
  autocomplete,
  icon,
  value,
  inputRef,
}: TextFieldProps) => {
  const readOnlyStyle = readOnly ? 'readOnly' : '';

  return (
    <>
      {label ? <div className={classNames(s.label)}>{label}</div> : null}
      <div className={classNames(s.wrapper, className, s[theme])} style={{ background, border }}>
        <input
          autoComplete={autocomplete}
          className={classNames(s.input, s[theme], s[readOnlyStyle])}
          name="input"
          onChange={(e) => {
            if (setValue) {
              setValue(e);
            }
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={inputRef}
          style={{ background }}
          type={type}
          value={value}
        />
        {isSearch ? (
          <div className={s.icon}>
            <SearchIcon />
          </div>
        ) : null}
        {icon && icon}
      </div>
      {error && <div className={classNames(s.error, s[`error-${theme}`])}>{error}</div>}
    </>
  );
};

export default TextField;
