import React from 'react';

type OptionalIconProps = {
  size?: string;
  className?: string;
  color?: string;
};

const UpDownIcon = ({ size = '25', className, color = '#898A8E' }: OptionalIconProps) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox="0 0 25 26"
    width={size}
    xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.46967 3.3014C7.76256 3.0085 8.23744 3.0085 8.53033 3.3014L13.0303 7.8014C13.3232 8.09429 13.3232 8.56916 13.0303 8.86206C12.7374 9.15495 12.2626 9.15495 11.9697 8.86206L8.75 5.64239V17.3317C8.75 17.7459 8.41421 18.0817 8 18.0817C7.58579 18.0817 7.25 17.7459 7.25 17.3317V5.64239L4.03033 8.86206C3.73744 9.15495 3.26256 9.15495 2.96967 8.86206C2.67678 8.56916 2.67678 8.09429 2.96967 7.8014L7.46967 3.3014Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.5303 22.3621C17.2374 22.6549 16.7626 22.6549 16.4697 22.3621L11.9697 17.8621C11.6768 17.5692 11.6768 17.0943 11.9697 16.8014C12.2626 16.5085 12.7374 16.5085 13.0303 16.8014L16.25 20.0211L16.25 8.33173C16.25 7.91751 16.5858 7.58173 17 7.58173C17.4142 7.58173 17.75 7.91751 17.75 8.33173L17.75 20.0211L20.9697 16.8014C21.2626 16.5085 21.7374 16.5085 22.0303 16.8014C22.3232 17.0943 22.3232 17.5692 22.0303 17.8621L17.5303 22.3621Z"
      fill={color}
      fillRule="evenodd"
      opacity="0.4"
    />
  </svg>
);

export default UpDownIcon;
