import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { ThemeType } from 'types/baseTypes';

interface IAppState {
  loading: boolean;
  theme: ThemeType;
  isOpenSideBar: boolean;
  now: number;
  cookiesNotification?: string;
  lang: string;
}

const themeFromCookie = Cookies.get('freegameTheme') || 'dark';
const cookieNotification = Cookies.get('cookieNotification');

const defaultLang = localStorage.getItem('lang') || 'En';

const initialState: IAppState = {
  loading: true,
  theme: themeFromCookie as ThemeType,
  isOpenSideBar: true,
  cookiesNotification: cookieNotification,
  now: dayjs().valueOf(),
  lang: defaultLang,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    changeTheme(state, action) {
      state.theme = action.payload;
    },
    setOpenSideBar(state, action) {
      state.isOpenSideBar = action.payload;
    },
    setNow(state, action) {
      state.now = action.payload;
    },
    langToggle(state, action) {
      state.lang = action.payload;
      localStorage.setItem('lang', action.payload);
    },
    setCookiesNotification(state, action) {
      if (action.payload === true) {
        Cookies.set('cookieNotification', 'true', {
          expires: 360,
        });
      }
      state.cookiesNotification = action.payload;
    },
  },
});

export const {
  setLoading,
  changeTheme,
  setOpenSideBar,
  setNow,
  setCookiesNotification,
  langToggle,
} = appSlice.actions;

export default appSlice.reducer;
