import React from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';

import App from './app';
import keycloak from './keycloak';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    autoRefreshToken
    initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false,
    }}>
    <React.StrictMode>
      <BrowserRouter basename="/">
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </ReactKeycloakProvider>,
);
