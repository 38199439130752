const en = {
  'app.lang': 'Русский',
  'app.lange.en': 'Английский',
  'app.lange.ru': 'Русский',
  'app.hide': 'Скрыть',
  'app.your-email': 'Ваш e-mail',
  'app.username': 'Никнейм',
  'app.wallet': 'Кошелёк',
  'app.new-password': 'Новый пароль',
  'app.at': 'в',
  'app.delete-bet': 'Удалить ставку',
  'app.show-more': 'Показать ещё',
  'app.deal-details': 'Детали сделки',
  'sidebar.finance-deals': 'Финансовые сделки',
  'sidebar.finance-deals.crypto': 'Криптовалюта',
  'sidebar.finance-deals.stocks': 'Акции',
  'sidebar.finance-deals.bonds': 'Облигации',
  'sidebar.finance-deals.currency': 'Валюты',
  'sidebar.finance-deals.metals': 'Металлы',
  'sidebar.finance-deals.product': 'Продукты',
  'sidebar.finance-deals.binary-options': 'Выше / Ниже',
  'sidebar.finance-deals.lottery': 'Дартс',
  'sidebar.finance-deals.jackpot': 'Джек Пул',
  'sidebar.finance-deals.make-bet': 'Добавить сделку',

  'header.main': 'Main',
  'header.my-deals': 'My Deals',
  'header.community': 'Community',
  'header.auth': 'Авторизация',

  'header.burger.dark-theme': 'Тёмная тема',
  'header.burger.my-transactions': 'My transactions',
  'header.burger.my-portfolio': 'Моё портфолио',
  'header.burger.copy-address': 'Copy address',
  'header.burger.log-out': 'Выйти',
  'header.burger.edit-profile': 'Редактировать профиль',

  'my-deals': 'My deals',
  'my-deals.finance-deals': 'Finance deals',
  'my-deals.search-for-deals': 'Search for a deal',
  'my-deals.high-liquidity': 'High liquidity',
  'my-deals.new': 'New',
  'my-deals.private': 'Private',
  'my-deals.will-the-btc': 'Will the BTC price be higher than xxxxx.xx at 12:00, 1 august 2022',
  'my-deals.common--pool-done': 'Common pool Done',
  'my-deals.bets': 'bets',

  portfolio: 'Portfolio',
  'portfolio.attempt-value': 'Количество попыток',
  'portfolio.my-deals': 'Мои сделки',
  'portfolio.win': 'Выигрыш',
  'portfolio.all-deals': 'All Deals',
  'portfolio.attempts-count': 'Количество попыток',
  'portfolio.finance': 'Finance',
  'portfolio.binary-options': 'Binary options',
  'portfolio.bet-amount': 'Сумма ставок',
  'portfolio.chose-outcome': 'Выбранный исход',
  'portfolio.will-be-higher': 'Will be higher',
  'portfolio.lower': 'lower',
  'portfolio.get': 'Забрать',
  'portfolio.outcome': 'Исход',
  'portfolio.received': 'Получено',
  'portfolio.date-time': 'Дата и время',
  'portfolio.transactions': 'Транзакции',
  'portfolio.adding-bet': 'Adding a bet',
  'portfolio.will-the-btc': 'Will the BTC price be higher than $xx.xxxx',
  'portfolio.deal-id': 'ID сделки',
  'portfolio.deleting-bet': 'Deleting a bet',
  'portfolio.predict-decrease-increase': 'Predict decrease or increase of the exchange rate',
  'portfolio.return-bet': 'Return of the bet',
  'portfolio.export-report': 'При экспорте отчёта возникла ошибка',
  'portfolio.deal-cancel-error': 'При отмене ставки возникла ошибка',
  'portfolio.store-data':
    'Мы всегда храним все данные любой закрытой сделки. В результатах вы сможете найти список ставок всех пользователей участвующих в данной сделке а так же итоговый курс на момент экспирации сделки.',
  'portfolio.download-csv': 'Скачать файл csv',
  'portfolio.transactions-result': 'Результаты сделки',
  'portfolio.final-rate': 'Итоговый курс',
  'portfolio.predict-btc': 'Спрогнозируйте курс BTC',

  'states.make-bet': 'Make a bet',
  'states.higher': 'Выше',
  'states.lower': 'Lower',
  'states.fee': 'Fee',
  'states.write-off-bet': 'Write off with the bet',
  'states.deal-ratio': 'Deal ratio',
  'states.maximum-win': 'Максимальный выигрыш',
  'states.bets-accepted': 'Ставка сделана',
  'states.bet-amount': 'Bet amount',
  'states.funds-pending': 'Funds pending',
  'states.will-be-accepted': 'Ваша ставка принята к сделке.',
  'states.your-bet': 'Ваша ставка',
  'states.deal-done': 'Сделка завершена',
  'states.error': 'Ошибка',
  'states.error-occured': 'Произошла ошибка при размещении ставки',

  'private-deal': 'This deal is private',
  'private-deal.only': 'Only those who has the link can view and participate in the deal',
  'private-deal.cop-link': 'Copy the link',

  'options.five-minute-game': '5 минутная',
  'options.weekly-game': 'Ежедневная с призом',
  'options.currency-pair-accepting': 'Currency pair accepting bets 12 minutes left ',
  'options.predict-increase-decrease': 'Predict increase or decrease of the exchange rate',
  'options.account': 'Аккаунт',
  'options.last-bets': 'Последние ставки',
  'options.my-bets': 'Мои ставки',

  'options.predict-on-date': 'Спрогнозируйте курс BTC на',
  'options.accepting-bets': 'Прием ставок',
  'options.currency-pair': 'валютная пара',
  'options.price-bet': 'цена 1 ставки',
  'options.waiting-result': 'Ждем результат',
  'options.deal-closed': 'Сделка закрыта',
  'options.pool-distributed': 'Пулл распределен',
  'options.meaning': 'Значение',
  'options.make-bet': 'Сделайте ставку',
  'options.price-will': 'Цена будет',
  'options.data-source': 'Источник данных',

  'notification.deal-completed': 'Deal completed',
  'notification.update-page': 'Чтобы увидеть новую – обновите страницу.',
  'notification.update': 'Обновить',
  'notification.collection-cookie': 'Уведомление о сборе cookie',
  'notification.we-use-cookie': 'Мы используем файлы cookie для улучшения работы сайта',
  'notification.cookie-ok': 'Понятно',
  'lottery.predict-price': 'Спрогнозируйте курс BTC через 5 минут',
  'jackpot.raffle-date': 'Raffle date',

  'dashboard.popular-assets': 'Popular assets',
  'dashboard.popular-deals': 'Popular deals',
  'dashboard.new-deals': 'New deals',
  'dashboard.view-deals': 'Viewed deals',
  'dashboard.bets-acception': 'Bets are acception',

  //   FREEGAME
  'freegame.be-part.header': 'Участвуй в ежедневном розыгрыше 100$ от P2PBET!',
  'freegame.be-part.text1':
    'Каждый день мы разыгрываем 100$ среди участников Free game. Используй свои 3 попытки,чтобы спрогнозировать курс BTC в заданное время. Участник, результат которого будет наиболее приближен или соответствовать курсу BTC на момент экспирации сделки заберет приз!',
  'freegame.be-part.text2':
    ' Но есть одно условие – чтобы забрать приз, нужно быть подписанным на одну из наших социальных сетей. И не забудь добавить адрес кошелька TRC20 в разделе редактирования  профиля.',
  'freegame.be-part.text2-part1':
    'Но есть одно условие – чтобы забрать приз, нужно быть подписанным на одну из наших социальных сетей. И не забудь добавить адрес кошелька TRC20 в разделе ',
  'freegame.be-part.text2-part2': 'редактирования  профиля.',
  'freegame.be-part.text2-part3': '',
  'freegame.daily-prize': 'FREE GAME с ежедневным призом',
  'freegame.five-minute': 'FREE GAME на 5 минут',

  'freegame.what-is': 'Что такое Free Game?',
  'freegame.desc':
    'Фри гейм - это платформа, полностью имитирующая функционал P2PBet, но без использования пользовательского кошелька метамаск. Помимо всех доступных на п2пбет финансовых инструментов, раз в неделю на платформе FreeGame среди зарегистрировавшихся пользователей будут разыгрываться реальные вознагржадения! Мы создали FreeGame для того, что бы вы могли поучавствовать в финансовых парии заработать деньги без вложений, ориентируясь только на свой расчет и интуицию. Удачи!',
  'freegame.amount-attempts': 'Кол-во попыток:',
  'freegame.predict-btc':
    'Спрогнозируйте курс BTC в заданное время. Участник, результат которого будет наиболее приближен или соответствовать фактическому курсу BTC/USDT на момент экспирации по бирже Binance, станет победителем.',
  'freegame.view-list': 'Показать список всех ставок',
  'freegame.view-list-my-bets': 'Показать мои ставки',
  'freegame.accrual': 'Accrual',
  'freegame.charging': 'Charging',
  'freegame.main-data': 'Основные данные',
  'freegame.payment-details': 'Платёжные данные',
  'freegame.login': 'Log in',
  'freegame.username-email': 'Username or Email',
  'freegame.do-have-account': 'Do you have an account? Log in',
  'freegame.signup': 'Авторизуйтесь',
  'freegame.leave': 'Покинуть режим Free Game',
  'freegame.back': 'Вернуться назад',
  'freegame.save-settings': 'Сохранить настройки',
  'freegame.repeat-password': 'Повторите пароль',
  'freegame.safety': 'Безопасность',
  'freegame.setting-saved': 'Настройки сохранены',
  'freegame.date-updated': 'Информация успешно обновлена.',
  'freegame.eroor-create': 'При сохранении данных произошла ошибка',
  'freegame.social-networks': 'Социальные сети',
  'freegame.loss': 'Проигрыш',
  'freegame.rateTitle': 'Текущий курс',
};

export default en;
