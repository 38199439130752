import React from 'react';

import { IconsTypes } from 'types/baseTypes';

const PersonIcon = ({ height = '19', width = '15', className, color = 'white' }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 15 19"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.87109 6.4868C1.87109 9.58283 4.40421 12.1159 7.50023 12.1159C10.5963 12.1159 13.1294 9.58283 13.1294 6.4868C13.1294 3.39078 10.5963 0.857666 7.50023 0.857666C4.40421 0.857666 1.87109 3.39078 1.87109 6.4868ZM11.8049 6.4868C11.8049 8.85435 9.86778 10.7914 7.50023 10.7914C5.13268 10.7914 3.1956 8.85435 3.1956 6.4868C3.1956 4.11926 5.13268 2.18217 7.50023 2.18217C9.86778 2.18217 11.8049 4.11926 11.8049 6.4868Z"
      fill={color}
    />
    <path
      d="M0.943708 18.1424C2.69868 16.3874 5.01656 15.4272 7.5 15.4272C9.98344 15.4272 12.3013 16.3874 14.0563 18.1424L15 17.1987C12.9967 15.2119 10.3311 14.1027 7.5 14.1027C4.66887 14.1027 2.00331 15.2119 0 17.1987L0.943708 18.1424Z"
      fill={color}
    />
  </svg>
);

export default PersonIcon;
