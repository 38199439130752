// @typescript-eslint/ban-ts-comment
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pluralen from 'plural';
import plural from 'plural-ru';

const formatLeft = (left: number, lang = 'En') => {
  const days = Math.floor(left / 60 / 60 / 24);
  const hours = Math.floor(left / 60 / 60);
  const minutes = Math.floor((left - hours * 60 * 60) / 60);
  const seconds = left % 60;
  let str = '';

  let daysString;
  let hoursString;
  let minutesString;
  let secondsString;

  if (lang === 'En') {
    daysString = pluralen('day', days);
    hoursString = pluralen('hour', hours);
    minutesString = pluralen('minute', minutes);
    secondsString = pluralen('second', seconds);
  }

  if (lang === 'Ru') {
    daysString = plural(days, 'день', 'дня', 'дней');
    hoursString = plural(hours, 'час', 'часа', 'часов');
    minutesString = plural(minutes, 'минута', 'минуты', 'минут');
    secondsString = plural(seconds, 'секунда', 'секунды', 'секунд');
  }

  if (days > 0) {
    str += `${days} ${daysString}`;
  } else {
    str += `${hours > 0 ? `${hours} ${hoursString}` : ''} ${
      minutes > 0 ? `${minutes} ${minutesString}` : ''
    } ${seconds > 0 ? `${seconds} ${secondsString}` : ''} `;
  }

  return str;
};

export default formatLeft;
