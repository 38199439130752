import { useMemo } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetClientInfoQuery } from 'store/services/apiService';
import { FreegameBetDuration, FreegameMode, setMode } from 'store/slices/freegameSlice';
import useMediaQuery from 'theme/useMediaQuery';

import Tabs from 'components/Simple/Tabs';
import Typography from 'components/Simple/Typography';
import { JoinStatus } from 'types/statuses';

import Translated from '../Translated';
import BetHistoryList from './BetHistoryList';
import s from './PortfolioBetHistory.module.scss';

interface CeilProps {
  title?: string;
  subTitle?: string;
}

const Ceil = ({ title = '', subTitle = '' }: CeilProps) => (
  <div>
    <Typography color="grey" variant="small">
      {title}
    </Typography>
    <Typography variant="h5">{subTitle}</Typography>
  </div>
);

const PortfolioBetHistory = () => {
  const theme = useSelector((state: RootState) => state.app.theme);
  const { keycloak } = useKeycloak();
  const isLaptop = useMediaQuery(1240);
  const dispatch = useDispatch();
  const { data: allJoins } = useGetClientInfoQuery(
    keycloak.tokenParsed?.preferred_username as string,
  );

  const totalWonAmount = useMemo(
    () => allJoins?.find((join) => join.joinStatus === JoinStatus.PRIZE_TAKEN)?.wonAmountTaken || 0,
    [allJoins],
  );

  const totalBetsAmount = useMemo(
    () =>
      allJoins?.reduce((acc, join) => {
        // eslint-disable-next-line no-param-reassign
        acc += join.totalJoin;
        return acc;
      }, 0),
    [allJoins],
  );

  const win = useTranslate('portfolio.win');
  const betAmount = useTranslate('portfolio.bet-amount');

  return (
    <div className={classNames(s.leftBlock, s[`leftBlock-${theme}`])} id="scrollablePortfolio">
      <div className={classNames(s.content, s[`content-${theme}`])}>
        {!isLaptop && (
          <div className={s.headTitleBlock}>
            <Typography variant="h1">
              <Translated id="portfolio.my-deals" />
            </Typography>
          </div>
        )}
        <div className={s.ceilBlock}>
          <Ceil subTitle={`${totalWonAmount} FreeBet`} title={win} />
          <Ceil subTitle={`${totalBetsAmount} FreeBet`} title={betAmount} />
        </div>
        <Tabs
          className={s.tabs}
          isDivider
          onTabClick={(tabNum) => {
            if (tabNum === 0) {
              dispatch(setMode(FreegameMode.short));
            } else {
              dispatch(setMode(FreegameMode.long));
            }
          }}
          tabsName={[
            <Translated id="options.five-minute-game" />,
            <Translated id="options.weekly-game" />,
          ]}
          theme={theme}>
          <div className={s.childTabs} key={FreegameBetDuration.short}>
            <BetHistoryList period={FreegameBetDuration.short} />
          </div>
          <div className={s.childTabs} key={FreegameBetDuration.long}>
            <BetHistoryList period={FreegameBetDuration.long} />
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default PortfolioBetHistory;
