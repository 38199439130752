import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import DartsIcon from 'components/Icons/DartsIcon';
import JackpoolIcon from 'components/Icons/JackpooIcon';
import PersonIcon from 'components/Icons/PersonIcon';
import UpDownIcon from 'components/Icons/UpDownIcon';
import { NavigateLinks, ThemeType } from 'types/baseTypes';

import freegameLogo from '../../../images/freegame-logo.svg';
import useColor from '../../../theme/useColor';
import useMediaQuery from '../../../theme/useMediaQuery';
import { FinanceIcon, FreegameIcon, LogoIcon } from '../../Icons';
import Button from '../../Simple/Button';
import Link from '../../Simple/Link';
import LangSelect from '../LangSelect';
import Translated from '../Translated';
import s from './SideBar.module.scss';

export const linkBaseUrl = 'https://app.p2pbet.io';
interface SideBarProps {
  theme?: ThemeType;
  financeMenuLinks: NavigateLinks[];
  freegameBtnFunction: () => void;
}

const SideBar = ({ theme = 'default', financeMenuLinks, freegameBtnFunction }: SideBarProps) => {
  const isLaptop = useMediaQuery(1024);
  const { grey, primary, white, lowDark } = useColor();

  const isDarkTheme = theme === 'dark';
  const location = useLocation();

  const getColor = (isDarkTh: boolean) => (isDarkTh ? white : lowDark);

  const handleFreegameButton = () => {
    freegameBtnFunction();
  };

  return (
    <aside className={`${s.wrapper} ${s[theme]}`}>
      {!isLaptop ? (
        <NavLink to="/">
          <div className={s.logoBlock}>
            <LogoIcon color={getColor(isDarkTheme)} />
            <img alt="logo" src={freegameLogo} />
          </div>
        </NavLink>
      ) : null}
      <div className={s.sideButtons}>
        <div className={classNames(s.sideSection, s.hideOnDesktop)}>
          <NavLink to="/portfolio">
            <Button
              align="start"
              color="grey"
              fullWidth
              size="mmd"
              startIcon={
                <PersonIcon
                  color={location.pathname === '/portfolio' ? primary : grey}
                  height="25"
                  width="25"
                />
              }
              theme={theme}
              variant="contained">
              <Translated id="header.burger.my-portfolio" />
            </Button>
          </NavLink>
        </div>
        <div className={s.sideSection}>
          <a href={`${linkBaseUrl}/bets`}>
            <Button
              align="start"
              color="grey"
              fullWidth
              size="mmd"
              startIcon={<FinanceIcon color={location.pathname === '/finance' ? primary : grey} />}
              theme={theme}
              variant="contained">
              <Translated id="sidebar.finance-deals" />
            </Button>
          </a>
          <ul className={s.list}>
            {financeMenuLinks.map(({ id, link, text }) => (
              <Link key={id} theme={theme} title={text} to={link} />
            ))}
          </ul>
        </div>
        <div className={s.sideSection}>
          <a href={`${linkBaseUrl}/up-down`}>
            <Button
              align="start"
              color="grey"
              fullWidth
              size="mmd"
              startIcon={
                <UpDownIcon color={location.pathname.includes('/up-down') ? primary : grey} />
              }
              theme={theme}
              variant="contained">
              <Translated id="sidebar.finance-deals.binary-options" />
            </Button>
          </a>
        </div>
        <div className={s.sideSection}>
          <a href={`${linkBaseUrl}/darts`}>
            <Button
              align="start"
              color="grey"
              fullWidth
              size="mmd"
              startIcon={
                <DartsIcon color={location.pathname.includes('/darts') ? primary : grey} />
              }
              theme={theme}
              variant="contained">
              <Translated id="sidebar.finance-deals.lottery" />
            </Button>
          </a>
        </div>
        <div className={s.sideSection}>
          <a href={`${linkBaseUrl}/jack-pool`}>
            <Button
              align="start"
              color="grey"
              fullWidth
              size="mmd"
              startIcon={<JackpoolIcon />}
              theme={theme}
              variant="contained">
              <Translated id="sidebar.finance-deals.jackpot" />
            </Button>
          </a>
        </div>
        <div className={s.sideSection}>
          <Button
            align="start"
            className={s.freegameButton}
            color="blue"
            fullWidth
            onClick={handleFreegameButton}
            size="mmd"
            startIcon={<FreegameIcon />}
            theme={theme}
            variant="contained">
            Free Game
          </Button>
        </div>
      </div>
      <LangSelect />
    </aside>
  );
};

export default SideBar;
