import React, { useMemo } from 'react';
import classNames from 'classnames';
import { BadgeVariant } from 'types/typings';
import { LockIcon } from '../../Icons';
import Typography from '../Typography';
import s from './index.module.scss';

interface BadgeProps {
  badgeTitle?: string;
  badgeVariant?: BadgeVariant;
  className?: string;
}

const Badge = ({ badgeVariant = '', className, badgeTitle }: BadgeProps) => {
  const typographyTitle = useMemo(() => {
    if (!badgeTitle) {
      return '';
    }
    return badgeVariant === 'private' ? (
      <span className={s.iconVariant}>
        <LockIcon />
        {badgeTitle}
      </span>
    ) : (
      badgeTitle
    );
  }, [badgeVariant, badgeTitle]);

  return (
    <div className={classNames(s[badgeVariant], s.badge, className)}>
      <Typography variant="smallBold">{typographyTitle}</Typography>
    </div>
  );
};

export default Badge;
