export const darkTheme = {
    chart: {
      layout: {
        backgroundColor: '#171A22',
        lineColor: '#2B2B43',
        textColor: '#D9D9D9',
      },
      rightPriceScale: {
        borderVisible: false,
      },
      timeScale: {
        borderColor: '#363C4E',
      },
      watermark: {
        color: 'rgba(0, 0, 0, 0)',
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: '#363C4E',
        },
      },
    },
    series: {
      upColor: '#08BD50',
      downColor: '#FA3E2C',
    },
  };

  
  export const lightTheme = {
    chart: {
      layout: {
        backgroundColor: '#FFFFFF',
        lineColor: '#2B2B43',
        textColor: '#191919',
      },
      watermark: {
        color: 'rgba(0, 0, 0, 0)',
      },
      rightPriceScale: {
        position: 'left',
        borderVisible: false,
      },
      leftPriceScale: {
        visible: true,
      },
      timeScale: {
        borderColor: '#f0f3fa',
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: '#f0f3fa',
        },
      },
    },
    series: {
      upColor: '#08BD50',
      downColor: '#FA3E2C',
    },
  };
  
 export const themesData = {
    dark: darkTheme,
    default: lightTheme,
  };
  