import React, { useCallback } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import BetInput from 'components/Simple/BetInput/BetInput';
import PulseBulb from 'components/Simple/PulseBulb';
import formatLeft from 'helpers/formatLeft';
import { SelectOptionsType, ThemeType } from 'types/baseTypes';

import { BtcIcon, TooltipIcon } from '../../Icons';
import AccordionSwitcher from '../../Simple/AccordionSwitcher';
import Button from '../../Simple/Button';
import SelectComponentWithCommission from '../../Simple/SelectComponentWithCommission';
import Tooltip from '../../Simple/Tooltip';
import Typography from '../../Simple/Typography';
import { Ceil } from '../RowBet/RowBet';
import s from './PlaceYourBet.module.scss';

interface PlaceYourBetProps {
  attempts?: number | string | null;
  title?: string;
  theme?: ThemeType;
  coefficient?: string;
  commission?: boolean;
  maxPrize?: string;
  tooltipCommission?: string;
  tooltipDeal?: string;
  tooltipPrize?: string;
  className?: string;
  betValue: string;
  accordionOff?: boolean;
  labelRangeSlider?: string;
  selectLabel?: string;
  tooltipDealLabel?: string | false;
  tooltipPrizeLabel?: string;
  onClickBet?: () => void;
  buttonGroup?: boolean;
  buttonGroupTitle?: string;
  tooltipButtonGroupTitle?: string;
  selectOptions?: SelectOptionsType[];
  onChangeSelect?: (value: any) => void;
  onBetChange?: (value: string) => void;
  onLogin?: () => void;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  betDisabled?: boolean;
  inputIcon?: JSX.Element;
  lockDate?: string | null;
  expirationDate?: string | null;
  now?: number | null;
  rate?: string;
  rateTitle: string | JSX.Element;
}

const PlaceYourBet = ({
  isAuthenticated,
  betValue,
  onBetChange,
  attempts,
  commission,
  theme = 'default',
  coefficient = '',
  maxPrize = '',
  tooltipDeal = '',
  tooltipPrize = '',
  tooltipCommission = '',
  title = 'Сделайте ставку',
  className,
  accordionOff = false,
  labelRangeSlider = 'Ваша ставка',
  selectLabel = 'Комиссия',
  tooltipDealLabel,
  tooltipPrizeLabel = 'Максимальный выигрыш',
  onClickBet,
  buttonGroup = false,
  buttonGroupTitle = '',
  tooltipButtonGroupTitle = 'tooltip',
  selectOptions,
  onChangeSelect,
  onLogin,
  isLoading,
  betDisabled,
  inputIcon,
  lockDate,
  expirationDate,
  now,
  rate,
  rateTitle,
}: PlaceYourBetProps) => {
  let pulseSubtitle = '';
  let pulseTitle = '';
  const pulseTime = '';
  let pulseVariant: 'open' | 'accepted' | 'closed' = 'open';

  const acceptingBets = useTranslate('options.accepting-bets');
  const waitingResult = useTranslate('options.waiting-result');
  const dealClosed = useTranslate('options.deal-closed');
  const poolDstributed = useTranslate('options.pool-distributed');
  const amountAttempts = useTranslate('freegame.amount-attempts');
  const signup = useTranslate('freegame.signup');
  const makeBets = useTranslate('options.make-bet');
  const { lang } = useSelector((state: RootState) => state.app);
  if (lockDate && expirationDate && now) {
    const lockDateLeft = Math.floor((dayjs(`${lockDate}Z`).valueOf() - now) / 1000);
    if (lockDateLeft > 0) {
      pulseTitle = acceptingBets;
      pulseSubtitle = formatLeft(lockDateLeft, lang);
    } else if (dayjs(`${expirationDate}Z`).valueOf() > now) {
      const expirationDateLeft = Math.floor((dayjs(`${expirationDate}Z`).valueOf() - now) / 1000);
      pulseVariant = 'accepted';
      pulseTitle = waitingResult;
      pulseSubtitle = formatLeft(expirationDateLeft, lang);
    } else {
      pulseVariant = 'closed';
      pulseTitle = dealClosed;
      pulseSubtitle = poolDstributed;
    }
  }

  const handleSetValue = useCallback(
    (value: string) => {
      if (onBetChange && /^[0-9]*\.?[0-9]*$/.test(value)) onBetChange(value);
    },
    [onBetChange],
  );

  return (
    <div className={classNames(s[theme], className)}>
      <div className={s.mobileHeader}>
        <div className={s.rateWrap}>
          <div className={s.rateIcon}>
            <BtcIcon />
          </div>
          <div className={s.rateInfo}>
            <div className={s.rateValue}>$ {rate}</div>
            <div className={s.rateLabel}>{rateTitle}</div>
          </div>
        </div>
        <div className={s.betStatus}>
          <Ceil
            leftBlock={<PulseBulb variant={pulseVariant} />}
            pulseTime={pulseTime}
            subTitle={pulseSubtitle}
            title={pulseTitle}
          />
        </div>
      </div>
      <div className={s.mb_20}>
        <Typography variant="h1">{title}</Typography>
      </div>
      {!accordionOff && (
        <div className={s.mb_20}>
          <AccordionSwitcher
            className={s.accordionSwitcherBlock}
            decreaseTitle="Ниже 20000"
            fullWidth
            increaseTitle="Выше 20000"
            theme={theme}
          />
        </div>
      )}
      {buttonGroup && (
        <>
          <div className={classNames(s.mb_5, s.dealBlock, s.btnGroupTitle)}>
            <Typography>{buttonGroupTitle}</Typography>
            <Tooltip theme={theme} toolTipText={tooltipButtonGroupTitle}>
              <TooltipIcon theme={theme} />
            </Tooltip>
          </div>
          <div className={classNames(s.mb_10, s.btnGroup)}>
            <div className={classNames(s.active)}>
              <Typography>10 $</Typography>
            </div>
            <div className={classNames(s.active)}>100 $</div>
            <div className={classNames(s.active)}>1000 $</div>
          </div>
        </>
      )}
      <div className={classNames(s.mb_5, s.dealBlock)}>
        <Typography>{labelRangeSlider}</Typography>
      </div>
      <div className={s.mb_20}>
        <BetInput
          background={theme === 'dark' ? '#171A22' : '#FFFFFF'}
          icon={inputIcon}
          placeholder="$"
          setValue={handleSetValue}
          theme={theme}
          value={betValue}
        />
      </div>
      {commission && (
        <>
          <div className={classNames(s.mb_5, s.row)}>
            <div className={s.commissionBlock}>
              <Typography>{selectLabel}</Typography>
              <Tooltip theme={theme} toolTipText={tooltipCommission}>
                <TooltipIcon theme={theme} />
              </Tooltip>
            </div>
          </div>
          <div className={s.mb_20}>
            <SelectComponentWithCommission
              onChange={onChangeSelect}
              options={selectOptions}
              theme={theme}
            />
          </div>
        </>
      )}
      {attempts !== undefined && (
        <>
          <div className={classNames(s.mb_10, s.row)}>
            <div className={s.commissionBlock}>
              <Typography>{amountAttempts}</Typography>
            </div>
            <div>{attempts}</div>
          </div>
          <div className={s.divider} />
        </>
      )}
      {tooltipDeal ||
        (tooltipDealLabel && (
          <>
            <div className={classNames(s.mb_10, s.row)}>
              <div className={s.commissionBlock}>
                {tooltipDealLabel && <Typography>{tooltipDealLabel}</Typography>}
                {tooltipDeal && (
                  <Tooltip theme={theme} toolTipText={tooltipDeal}>
                    <TooltipIcon theme={theme} />
                  </Tooltip>
                )}
              </div>
              <div>{coefficient}</div>
            </div>
            <div className={s.divider} />
          </>
        ))}
      <div className={classNames(s.mb_25, s.row)}>
        <div className={s.commissionBlock}>
          <Typography>{tooltipPrizeLabel}</Typography>
          {tooltipPrize && (
            <Tooltip theme={theme} toolTipText={tooltipPrize}>
              <TooltipIcon theme={theme} />
            </Tooltip>
          )}
        </div>
        <div>{maxPrize}</div>
      </div>
      <div>
        {isAuthenticated ? (
          <Button
            color="secondary"
            disabled={betDisabled}
            fullWidth
            isLoading={isLoading}
            onClick={onClickBet}
            size="xl"
            theme={theme}>
            {!isLoading ? makeBets : 'Processing'}
          </Button>
        ) : (
          <Button color="primary" fullWidth onClick={onLogin} size="xl" theme={theme}>
            {signup}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PlaceYourBet;
