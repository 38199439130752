import { FreegameBetStatus } from 'store/slices/freegameSlice';

import { TableTd } from 'components/Containers/Table/TableRow/TableRow';
import { CircleIcon } from 'components/Icons';
import CrownOutlineIcon from 'components/Icons/CrownOutlineIcon';
import formatDate from 'helpers/formatDate';
import { IJoin } from 'types/join';

import styles from '../pages/freegame/freegame.module.scss';

const closest = (goal: number, data: IJoin[], username: string | undefined) => {
  const filteredByUserName = data.filter((join) => join.client === username);
  if (filteredByUserName.length > 0) {
    return filteredByUserName.reduce((prev, curr) =>
      Math.abs(parseInt(curr.targetValue, 10) - goal) <
      Math.abs(parseInt(prev.targetValue, 10) - goal)
        ? curr
        : prev,
    );
  }
  return null;
};

const mapJoinsToTable = (
  data: IJoin[],
  betStatus: FreegameBetStatus,
  leader: IJoin | null | undefined,
  username: string | undefined,
  currentRate: string,
  lang: string,
) => {
  let joinsData = [...data];
  const closestCurrentUser = closest(parseInt(currentRate, 10), joinsData, username);
  if (closestCurrentUser) {
    joinsData = joinsData.filter((join) => join.id !== closestCurrentUser.id);
  }

  if (leader) {
    joinsData = joinsData.filter((join) => join.id !== leader.id);
  }

  const mappedJoins = joinsData.reduce((acc, join) => {
    const formattedDate = formatDate(join.createdDate, 'D MMMM, HH:mm', lang);
    acc.push([
      <span className={styles.clientName}>{join.client}</span>,
      betStatus === FreegameBetStatus.open && join.client !== username
        ? 'xxxxxx.xx'
        : `$${join.targetValue}`,
      formattedDate,
    ]);
    return acc;
  }, [] as TableTd[][]);

  if (leader && leader?.client === username) {
    mappedJoins.unshift([
      <div>
        <span className={styles.clientName}>{leader.client}</span>
        <CrownOutlineIcon className={styles.leaderCrownIcon} />
        <CircleIcon className={styles.closestCurrentUserCircleIcon} stopColor="#152338" />
      </div>,
      `$${leader.targetValue}`,
      formatDate(leader.createdDate, 'D MMMM, HH:mm', lang),
    ]);
  } else if (closestCurrentUser) {
    mappedJoins.splice(0, 0, [
      <div>
        <span className={styles.clientName}>{closestCurrentUser.client} </span>
        <CircleIcon className={styles.closestCurrentUserCircleIcon} stopColor="#152338" />
      </div>,
      `$${closestCurrentUser.targetValue}`,
      formatDate(closestCurrentUser.createdDate, 'D MMMM, HH:mm', lang),
    ]);

    if (!!leader && betStatus !== FreegameBetStatus.open) {
      mappedJoins.unshift([
        <div>
          <span className={styles.clientName}>{leader.client}</span>
          <CrownOutlineIcon className={styles.leaderCrownIcon} />
        </div>,
        `$${leader.targetValue}`,
        formatDate(leader.createdDate, 'D MMMM, HH:mm', lang),
      ]);
    }
  } else if (leader) {
    mappedJoins.unshift([
      <div>
        <span className={styles.clientName}>{leader.client}</span>
        <CrownOutlineIcon className={styles.leaderCrownIcon} />
      </div>,
      betStatus === FreegameBetStatus.open ? `xxxxxx.xx` : `$${leader.targetValue}`,
      formatDate(leader.createdDate, 'D MMMM, HH:mm', lang),
    ]);
  }

  return mappedJoins;
};

export default mapJoinsToTable;
