import React, { useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import plural from 'plural-ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FreegameBetDuration } from 'store/slices/freegameSlice';

import Translated from 'components/Containers/Translated';
import formatDate from 'helpers/formatDate';
import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import useMediaQuery from '../../../theme/useMediaQuery';
import ChevronIcon from '../../Icons/ChevronIcon';
import Button from '../Button';
import CurrencyPair from '../CurrencyPair';
import Typography from '../Typography';
import s from './MyDealcard.module.scss';

interface MyDealCardProps {
  children?: Children;
  expirationDate: string;
  theme?: ThemeType;
  iconIn?: React.ReactNode;
  iconOut?: React.ReactNode;
  attemptsCount: number;
  choose?: string;
  prize?: string | number;
  finalValue?: string;
  button?: JSX.Element | null;
  period: FreegameBetDuration;
  sumBets?: string | number;
}

const MyDealCard = ({
  children,
  theme = 'default',
  iconIn,
  iconOut,
  attemptsCount,
  choose,
  prize,
  finalValue,
  button,
  period,
  sumBets,
  expirationDate,
}: MyDealCardProps) => {
  const lang = useSelector((state: RootState) => state.app.lang);

  const [isOpen, setIsOpen] = useState(false);

  const isLaptop = useMediaQuery(1024);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  const predictPrice = useTranslate('lottery.predict-price');
  const predictOnDate = useTranslate('options.predict-on-date');
  const at = useTranslate('app.at');

  const attepmtsLabel =
    lang === 'Ru'
      ? plural(attemptsCount, 'попытка', 'попытки', 'попыток')
      : plural(attemptsCount, 'attempt', 'attempts');
  const title =
    period === FreegameBetDuration.short
      ? predictPrice
      : `${predictOnDate} ${formatDate(expirationDate, 'D MMMM YYYY HH:mm', lang)}`;
  const timeTitle = `${at} ${formatDate(expirationDate, 'HH:mm, D MMMM YYYY', lang)}`;
  return (
    <div className={classNames(s.wrapper, s[theme])}>
      <div className={s.headerBlock}>
        {iconIn && iconOut ? (
          <CurrencyPair iconLeft={iconIn} iconRight={iconOut} theme={theme} />
        ) : null}
        <div className={s.centerText}>
          <Typography variant="h4">{title}</Typography>
          <Typography color="grey">
            {period === FreegameBetDuration.short ? timeTitle : ' '}
          </Typography>
        </div>
        {!isLaptop && button ? button : null}
      </div>
      <div className={s.infoBlock}>
        <div className={s.infoItem}>
          {period === FreegameBetDuration.short ? (
            <>
              <Typography color="grey" variant="subtitle">
                <Translated id="portfolio.bet-amount" />
              </Typography>
              <Typography>{sumBets}</Typography>
            </>
          ) : (
            <>
              <Typography color="grey" variant="subtitle">
                <Translated id="portfolio.attempts-count" />
              </Typography>
              <Typography>{`${attemptsCount} ${attepmtsLabel}`}</Typography>
            </>
          )}
        </div>
        {choose && (
          <div className={s.infoItem}>
            <Typography color="grey" variant="subtitle">
              <Translated id="portfolio.chose-outcome" />
            </Typography>
            <Typography color="green">{choose}</Typography>
          </div>
        )}

        {!!prize && (
          <div className={s.infoItem}>
            <Typography color="grey" variant="subtitle">
              <Translated id="portfolio.win" />
            </Typography>
            <Typography>{prize}</Typography>
          </div>
        )}
        {finalValue && (
          <div className={s.infoItem}>
            <Typography color="grey" variant="subtitle">
              <Translated id="portfolio.final-rate" />
            </Typography>
            <Typography>{finalValue}</Typography>
          </div>
        )}
      </div>
      {isLaptop && button ? <div className={classNames(s.infoButtons)}>{button}</div> : null}
      {isOpen ? <div className={s.content}>{children}</div> : null}
      <div className={s.showBets}>
        <Button
          className={s.betButton}
          endIcon={<ChevronIcon className={classNames(isOpen && s.rotateChevron)} />}
          onClick={handleOpen}
          variant="outlined">
          {isOpen ? <Translated id="app.hide" /> : <Translated id="freegame.view-list-my-bets" />}
        </Button>
      </div>
    </div>
  );
};

export default MyDealCard;
