import React from 'react';
import classNames from 'classnames';
import { TypographyVariantType } from 'types/baseTypes';
import { Children } from 'types/children';
import palette from '../../../theme/Palette.module.scss';
import s from './Typography.module.scss';

const transformTypography = (
  variant: TypographyVariantType,
  classes: string,
  children?: Children,
) => {
  if (variant === 'h1') {
    return <h1 className={classes}>{children}</h1>;
  }
  if (variant === 'h2') {
    return <h2 className={classes}>{children}</h2>;
  }
  if (variant === 'h3') {
    return <h3 className={classes}>{children}</h3>;
  }
  return <p className={classes}>{children}</p>;
};

interface TypographyProps {
  children?: Children;
  variant?: TypographyVariantType;
  align?: 'center' | 'inherit' | 'left' | 'right';
  color?: 'white' | 'grey' | 'dark' | 'primary' | 'darkblue' | 'green' | 'cardTooltip' | 'inherit';
}

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body1',
  align = 'left',
  color = '',
}) => {
  const classes = classNames(s.main, s[variant], s[align], palette[color]);
  return transformTypography(variant, classes, children);
};

export default Typography;
