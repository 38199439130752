/* eslint-disable react/no-array-index-key */
import useTranslate from 'hooks/useTranslate.hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Spinner from 'components/Simple/Spinner/Spinner';

import TableMain from '../Table/TableMain';
import TableRow, { TableTd } from '../Table/TableRow/TableRow';
import s from './JoinTable.module.scss';

interface JoinTableProps {
  tableRows: TableTd[][];
  hasMore: boolean;
  loadMore: () => void;
}

const JoinTable = ({ tableRows, hasMore, loadMore }: JoinTableProps) => {
  const { theme } = useSelector((state: RootState) => state.app);

  const account = useTranslate('options.account');
  const date = useTranslate('portfolio.date-time');
  const meaning = useTranslate('options.meaning');

  return (
    <div className={s.tableWrap} id="joinTable">
      <InfiniteScroll
        dataLength={tableRows.length}
        hasMore={hasMore}
        loader={<Spinner />}
        next={loadMore}
        scrollableTarget="joinTable">
        <TableMain columnNames={[account, meaning, date]} hasShowMoreButton={false} theme={theme}>
          {tableRows.map((row, index) => (
            <TableRow key={index} namesTd={row} theme={theme} />
          ))}
        </TableMain>
      </InfiniteScroll>
    </div>
  );
};

export default JoinTable;
