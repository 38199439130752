import React, { useState } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import { ChevronIcon } from '../../../Icons';
import Button from '../../../Simple/Button';
import TableBody from '../TableBody/TableBody';
import s from './TableMain.module.scss';

interface TableMainProps {
  theme?: ThemeType;
  children?: Children;
  columnNames: string[];
  hasShowMoreButton?: boolean;
}

const TableMain = ({
  theme = 'default',
  children,
  columnNames = [],
  hasShowMoreButton = true,
}: TableMainProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };
  const showMore = useTranslate('app.show-more');
  const hide = useTranslate('app.hide');
  return (
    <div className={classNames(s.wrapper, s[theme])}>
      <TableBody columnNames={columnNames} theme={theme}>
        {hasShowMoreButton && !isOpen
          ? Array.isArray(children) && (children as []).slice(0, 5)
          : children}
      </TableBody>
      {hasShowMoreButton && children && (children as []).length > 5 && (
        <div className={s.showMore}>
          <Button
            className={s.betButton}
            endIcon={<ChevronIcon className={classNames(isOpen && s.rotateChevron)} />}
            onClick={handleOpen}
            variant="outlined">
            {!isOpen ? showMore : hide}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TableMain;
