import classNames from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Modals, openModal } from 'store/slices/modalsSlice';

import Button from 'components/Simple/Button';
import TextField from 'components/Simple/TextField';
import { ThemeType } from 'types/baseTypes';

import Modal from '../Modal';
import s from './LoginFormModal.module.scss';

interface LoginFormModalProps {
  isOpen: boolean;
  setOpen: () => void;
  theme: ThemeType;
}

const LoginFormModal = ({ isOpen, setOpen, theme }: LoginFormModalProps) => {
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, control } = methods;

  const onSubmit = () => {};

  const handleRegister = () => {
    dispatch(openModal(Modals.register));
  };

  const handleForgotPass = () => {
    dispatch(openModal(Modals.forgotPass));
  };

  return (
    <Modal open={isOpen} setOpen={setOpen} theme={theme}>
      <header className={classNames(s.header, s[theme])}>
        <h2 className={s.title}>Авторизация</h2>
      </header>
      <FormProvider {...methods}>
        <form className={s.form}>
          <div className={s.field}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  label="Ваш e-mail"
                  placeholder=""
                  setValue={field.onChange}
                  theme={theme}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={s.field}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  label={
                    <div className={s.passLabel}>
                      <span>Пароль</span>
                      <button
                        className={classNames(s.forgotPass, s.buttonLink)}
                        onClick={handleForgotPass}
                        type="button">
                        Забыли пароль?
                      </button>
                    </div>
                  }
                  placeholder=""
                  setValue={field.onChange}
                  theme={theme}
                  type="password"
                  value={field.value}
                />
              )}
            />
          </div>
          <div>
            <Button
              className={s.submitButton}
              color="secondary"
              onClick={() => handleSubmit(onSubmit)()}
              size="xl">
              Авторизация
            </Button>
          </div>
          <footer className={classNames(s.footer, s[theme])}>
            <div>
              У вас еще нет аккаунта?&nbsp;
              <button className={classNames(s.buttonLink)} onClick={handleRegister} type="button">
                Зарегистрируйтесь
              </button>
            </div>
          </footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default LoginFormModal;
