import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { ThemeType } from 'types/baseTypes';

import Typography from '../../Simple/Typography';
import s from './navigateBlock.module.scss';

interface NavigateBlockProps {
  navigateLinks: IncomeNavigateLink[];
  theme?: ThemeType;
}

interface IncomeNavigateLink {
  id: number;
  link: string;
  text: ReactNode;
}

const NavigateBlock = ({ navigateLinks, theme = 'default' }: NavigateBlockProps) => (
  <div className={`${s.navigateBlock} ${s[theme]}`}>
    {navigateLinks.map(({ link, text, id }) => (
      <Link key={id} to={link}>
        <Typography>{!text}</Typography>
      </Link>
    ))}
  </div>
);

export default NavigateBlock;
