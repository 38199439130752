import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useLazyGetEventByIdQuery } from 'store/services/apiService';
import { setCurrentRate } from 'store/slices/freegameSlice';

import Chart1 from 'components/chart1';

const FreegameChart = () => {
  const ref = useRef();
  const theme = useSelector((state: RootState) => state.app.theme);
  const currentRate = useSelector((state: RootState) => state.freegame.currentRate);
  const activeBet = useSelector((state: RootState) => state.freegame.activeBet);
  const dispatch = useDispatch();
  const [getEvent, { data: event }] = useLazyGetEventByIdQuery();
  const handleRateChange = (rate: string) => {
    dispatch(setCurrentRate(rate));
  };
  useEffect(() => {
    if (activeBet) {
      getEvent(activeBet.eventId);
    }
  }, [activeBet, getEvent]);
  return (
    <Chart1
      colors={{}}
      currentRate={currentRate}
      ref={ref}
      setCurrentRate={handleRateChange}
      theme={theme}
      type={event?.symbol}
    />
  );
};

export default FreegameChart;
