import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetUserDataQuery } from 'store/services/apiService';

import EditProfile from 'components/Containers/EditProfile/EditProfile';
import Layout from 'components/Containers/Layout';

const Profile = () => {
  const theme = useSelector((state: RootState) => state.app.theme);
  const { data: userData } = useGetUserDataQuery(null);

  return (
    <Layout>
      {userData && (
        <EditProfile
          email={userData.email}
          rewardAddress={userData.rewardAddress}
          socialNetworks={userData.socialNetworks}
          theme={theme}
          username={userData.username as string}
        />
      )}
    </Layout>
  );
};

export default Profile;
