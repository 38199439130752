import { useCallback, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { WebsocketProvider } from 'context/websocket';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';
import { useLazyGetBalanceQuery, useLazyGetTimeQuery } from 'store/services/apiService';
import { setNow } from 'store/slices/appSlice';
import { setBalance, setUser } from 'store/slices/userSlice';

import ProtectedRoute from 'components/Containers/ProtectedRoute/ProtectedRoute';
import Freegame from 'pages/freegame';
import Portfolio from 'pages/portfolio';
import Profile from 'pages/profile';

function App() {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();
  const { token, tokenParsed } = keycloak;
  const [getBalance, { data: balance }] = useLazyGetBalanceQuery();
  const [getServerTime, { data: serverTime }] = useLazyGetTimeQuery();

  const fetchServerTime = useCallback(() => {
    if (!document.hidden) {
      getServerTime(null);
    }
  }, [getServerTime]);
  const now = useSelector((state: RootState) => state.app.now);

  useEffect(() => {
    if (tokenParsed) {
      dispatch(
        setUser({
          email: tokenParsed.email,
          email_verified: tokenParsed.email_verified,
          family_name: tokenParsed.family_name,
          given_name: tokenParsed.given_name,
          socialNetworks: tokenParsed.socialNetworks,
        }),
      );
    }
  }, [dispatch, tokenParsed, token]);

  useEffect(() => {
    if (token) {
      getBalance(null);
    }
  }, [token, getBalance]);

  useEffect(() => {
    if (balance) {
      dispatch(setBalance(balance.amount));
    }
  }, [balance, dispatch]);

  useEffect(() => {
    if (serverTime) {
      const unixTime = dayjs(`${serverTime.date}Z`.replace(/ /g, 'T')).valueOf();
      dispatch(setNow(unixTime));
    }
  }, [dispatch, serverTime]);

  useEffect(() => {
    const defTimer = setInterval(() => {
      const nextSecondTick = dayjs(now).add(1, 'second').valueOf();
      dispatch(setNow(nextSecondTick));
    }, 1000);

    document.addEventListener('visibilitychange', fetchServerTime);

    return () => {
      clearInterval(defTimer);
      document.removeEventListener('visibilitychange', fetchServerTime);
    };
  }, [dispatch, now, fetchServerTime]);

  useEffect(() => {
    getServerTime(null);
  }, [getServerTime]);

  return initialized ? (
    <WebsocketProvider>
      <Routes>
        <Route element={<Freegame />} path="/" />
        <Route
          element={
            <ProtectedRoute>
              <Portfolio />
            </ProtectedRoute>
          }
          path="/portfolio"
        />
        <Route
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          path="/profile"
        />
      </Routes>
    </WebsocketProvider>
  ) : (
    <div />
  );
}

export default App;
