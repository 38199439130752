import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const Triangle = ({ width = '8', height = '25', className, color = 'white' }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 8 25"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12.998L0.844584 12.5758C1.34844 12.3238 1.60037 12.1979 1.82886 12.0709C5.47468 10.0449 7.80699 6.27116 7.98862 2.1042C8 1.84304 8 1.56138 8 0.998047V24.998C8 24.4347 8 24.1531 7.98862 23.8919C7.80699 19.7249 5.47468 15.9512 1.82886 13.9252C1.60038 13.7982 1.34846 13.6723 0.844643 13.4204L0.844584 13.4203L0 12.998Z"
      fill={color}
    />
  </svg>
);

export default Triangle;
