import { useKeycloak } from '@react-keycloak/web';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useTranslate from 'hooks/useTranslate.hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import {
  useCancelBetMutation,
  useExportBetReportMutation,
  useGetPersonalBetsQuery,
  useTakePrizeMutation,
} from 'store/services/apiService';
import { FreegameBetDuration } from 'store/slices/freegameSlice';
import useMediaQuery from 'theme/useMediaQuery';

import Spinner from 'components/Simple/Spinner/Spinner';
import { PersonalAuctionBet } from 'types/auctionBet';
import { IJoin } from 'types/join';

import PortfolioDealCard from '../PortfolioDealCard/PortfolioDealCard';
import Toast from '../Toast/Toast';
import ToastContent from '../Toast/ToastContent';
import s from './PortfolioBetHistory.module.scss';

interface BetHistoryListProps {
  period: FreegameBetDuration;
}

const BetHistoryList = ({ period }: BetHistoryListProps) => {
  const { keycloak } = useKeycloak();
  const theme = useSelector((state: RootState) => state.app.theme);
  const [takePrize] = useTakePrizeMutation();
  const [cancelBet] = useCancelBetMutation();
  const [exportReport] = useExportBetReportMutation();
  const isLaptop = useMediaQuery(1024);

  const {
    combinedData: bets,
    loadMore,
    hasMore,
  } = useInfiniteScroll<PersonalAuctionBet>(useGetPersonalBetsQuery, {
    page: 0,
    size: 10,
    clientAddress: keycloak.tokenParsed?.preferred_username,
    executionType: 'FREE',
    periodFilter: [
      {
        condition: 'GREATER_EQUAL_THAN',
        period,
      },
      {
        condition: 'LESS_EQUAL_THAN',
        period,
      },
    ],
    sort: {
      direction: 'DESC',
      property: 'createdDate',
    },
  });

  const onTakePrize = (bet: PersonalAuctionBet) => {
    takePrize({ betId: bet.id });
  };
  const errorCancel = useTranslate('portfolio.deal-cancel-error');
  const exportReportText = useTranslate('portfolio.export-report');
  const onExport = async (bet: PersonalAuctionBet) => {
    try {
      if (bet.finalValue) {
        exportReport({
          betId: bet.id,
          value: bet.finalValue as string,
        });
      } else {
        const response = await fetch(
          `https://finnhub.io/api/v1/crypto/candle?symbol=BINANCE:BTCUSDT&resolution=1&count=1&token=cdplhcaad3ia8s05dv9gcdplhcaad3ia8s05dva0`,
        );
        const data = await response.json();
        const value = data.c[0];
        exportReport({
          betId: bet.id,
          value,
        });
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message || '';
      toast(<ToastContent text={errorMessage} title={exportReportText} />);
    }
  };

  const onDelete = async (joinToDelete: IJoin, bet: PersonalAuctionBet) => {
    try {
      await cancelBet({
        betId: bet.id,
        joinIdRef: joinToDelete.joinIdClientRef,
      }).unwrap();
    } catch (error: any) {
      const errorMessage = error?.data?.message || '';
      toast(<ToastContent text={errorMessage} title={errorCancel} />);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={bets.length}
        hasMore={hasMore}
        loader={<Spinner />}
        next={loadMore}
        scrollableTarget={isLaptop ? undefined : 'scrollablePortfolio'}>
        <div className={s.childTabs}>
          {bets.map((bet) => (
            <PortfolioDealCard
              bet={bet}
              key={bet.id}
              onDelete={onDelete}
              onExport={onExport}
              onTakePrize={() => onTakePrize(bet)}
              period={period}
              theme={theme}
            />
          ))}
        </div>
      </InfiniteScroll>
      <Toast />
    </>
  );
};

export default BetHistoryList;
