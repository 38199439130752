import { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import useTranslate from 'hooks/useTranslate.hook';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RootState } from 'store';
import { FreegameBetDuration } from 'store/slices/freegameSlice';
import useMediaQuery from 'theme/useMediaQuery';

import { BtcIcon, TetherIcon } from 'components/Icons';
import MenuIcon from 'components/Icons/MenuIcon';
import Button from 'components/Simple/Button';
import MyDealCard from 'components/Simple/MyDealCard';
import StatusTag from 'components/Simple/StatusTag/StatusTag';
import { PersonalAuctionBet } from 'types/auctionBet';
import { ThemeType } from 'types/baseTypes';
import { IJoin } from 'types/join';
import { JoinStatus } from 'types/statuses';

import s from '../../Simple/MyDealCard/MyDealcard.module.scss';
import Modal from '../Modal';
import TableBody from '../Table/TableBody';
import TableRow from '../Table/TableRow';
import Translated from '../Translated';
import mapJoinsToPortfolio, { wonStatuses } from './map-joins';

interface PortfolioDealCardProps {
  bet: PersonalAuctionBet;
  onTakePrize: (bet: PersonalAuctionBet) => void;
  onExport: (bet: PersonalAuctionBet) => void;
  onDelete: (join: IJoin, bet: PersonalAuctionBet) => void;
  theme: ThemeType;
  period: FreegameBetDuration;
}

const PortfolioDealCard = ({
  bet,
  onTakePrize,
  onDelete,
  theme,
  onExport,
  period,
}: PortfolioDealCardProps) => {
  const now = useSelector((state: RootState) => state.app.now);
  const lang = useSelector((state: RootState) => state.app.lang);
  const lockDateLeft = Math.floor((dayjs(`${bet.lockDate}Z`).valueOf() - now) / 1000);
  const [exportModalIsOpened, setExportModalIsOpened] = useState(false);
  const isTablet = useMediaQuery(1024);

  const viewList = useTranslate('freegame.view-list');
  const received = useTranslate('portfolio.received');
  const loss = useTranslate('freegame.loss');
  const betAmount = useTranslate('portfolio.bet-amount');
  const attemptCount = useTranslate('portfolio.attempts-count');
  const dateAndTime = useTranslate('portfolio.date-time');
  const outcome = useTranslate('portfolio.outcome');
  const attepmtsLabel = lang === 'Ru' ? 'попытка' : 'attempt';

  const exportButton = useCallback(
    () => (
      <>
        <Button
          className={s.exportButton}
          color="grey"
          dataFor="export-bets"
          dataTip
          onClick={() => setExportModalIsOpened(true)}
          startIcon={<MenuIcon />}
          theme={theme}>
          <span className={s.exportButtonText}>{viewList}</span>
        </Button>
        <ReactTooltip
          className={s.tooltip}
          disable={isTablet}
          effect="solid"
          id="export-bets"
          place="top"
          type="dark">
          {viewList}
        </ReactTooltip>
      </>
    ),
    [isTablet, theme, viewList],
  );

  const renderPrizeButton = useCallback(() => {
    switch (bet.personalAggregatedStatus) {
      case 'WON':
        return (
          <div className={s.takeButtonWrap}>
            {exportButton()}
            <Button
              className={s.button}
              color="secondary"
              onClick={() => onTakePrize(bet)}
              theme={theme}
              variant="contained">
              <Translated id="portfolio.get" />
            </Button>
          </div>
        );
      case 'PRIZE_TAKEN':
        return (
          <div className={s.takeButtonWrap}>
            {exportButton()}
            <Button
              className={s.button}
              color="secondary"
              disabled
              theme={theme}
              variant="contained">
              {received}
            </Button>
          </div>
        );
      case 'LOST':
        return (
          <div className={s.takeButtonWrap}>
            {exportButton()}
            <StatusTag text={loss} variant="dark-red" />
          </div>
        );
      default:
        if (lockDateLeft <= 0) {
          return <div className={s.takeButtonWrap}>{exportButton()}</div>;
        }

        return null;
    }
  }, [bet, exportButton, theme, lockDateLeft, onTakePrize, received, loss]);

  const inactiveStatuses = [JoinStatus.CANCELED, JoinStatus.REFUNDED];
  const personalBetsSum = useMemo(
    () =>
      bet.personalJoins.reduce((acc, join) => {
        // eslint-disable-next-line no-param-reassign
        acc += join.joinAmount;
        return acc;
      }, 0),
    [bet],
  );

  const activeJoins = bet.personalJoins.filter(
    (join) => inactiveStatuses.indexOf(join.status) === -1,
  );

  const joins = mapJoinsToPortfolio(activeJoins, lang, period, attepmtsLabel);
  const noPrizeStatuses = [JoinStatus.CANCELED, JoinStatus.LOST, JoinStatus.REFUNDED];
  const hasPrize = noPrizeStatuses.indexOf(bet.personalAggregatedStatus) === -1;

  return (
    <>
      <MyDealCard
        attemptsCount={bet.personalJoins.length}
        button={renderPrizeButton()}
        expirationDate={bet.expirationDate}
        finalValue={bet.finalValue ? `${bet.finalValue} $` : ''}
        iconIn={<BtcIcon />}
        iconOut={<TetherIcon />}
        period={period}
        prize={hasPrize ? `${bet.totalPool} FreeBet` : undefined}
        sumBets={`${personalBetsSum} FreeBet`}
        theme={theme}>
        <TableBody
          columnNames={[
            outcome,
            period === FreegameBetDuration.long ? attemptCount : betAmount,
            dateAndTime,
            '',
          ]}
          theme={theme}>
          {joins.map((joinData: any) => (
            <TableRow
              hasMenu
              key={joinData.join.id}
              menuIsVisible={false}
              namesTd={joinData.tableData}
              onDelete={() => onDelete(joinData.join, bet)}
              theme={theme}
              variant={wonStatuses.indexOf(joinData.join.status) > -1 && 'green'}
            />
          ))}
        </TableBody>
      </MyDealCard>
      {exportModalIsOpened && (
        <Modal
          open={exportModalIsOpened}
          setOpen={() => setExportModalIsOpened(false)}
          theme={theme}>
          <div className={classNames(s.exportModal, s[theme])}>
            <h2 className={s.exportTitle}>
              <Translated id="portfolio.transactions-result" />
            </h2>
            <p className={s.exportText}>
              <Translated id="portfolio.store-data" />
            </p>
            <div className={s.exportFooter}>
              <div className={s.exportIdWrap}>
                <div className={s.exportIdLabel}>
                  <Translated id="portfolio.deal-id" />
                </div>
                <div className={s.exportIdValue}>{bet.id}</div>
              </div>
              <div className={s.exportButtonWrap}>
                <Button
                  className={s.button}
                  color="secondary"
                  onClick={() => onExport(bet)}
                  theme={theme}
                  variant="contained">
                  <Translated id="portfolio.download-csv" />
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PortfolioDealCard;
