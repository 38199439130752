/* eslint-disable react/no-array-index-key */
import React from 'react';

import classNames from 'classnames';

import Translated from 'components/Containers/Translated';
import { ThemeType } from 'types/baseTypes';

import useColor from '../../../../theme/useColor';
import { CrumbsIcon } from '../../../Icons/index';
import Button from '../../../Simple/Button';
import Label from '../../../Simple/Label';
import Menu from '../../Menu';
import s from './TableRow.module.scss';

export type TableTd = string | JSX.Element;

interface TableRowProps {
  namesTd: TableTd[];
  hasMenu?: boolean;
  menuIsVisible?: boolean;
  theme: ThemeType;
  onDelete?: () => void;
  variant?: 'default' | 'green' | false;
}

type ArrType = (string | React.ReactNode | JSX.Element)[];

const TableRow = ({
  namesTd,
  hasMenu,
  menuIsVisible,
  theme,
  onDelete,
  variant = 'default',
}: TableRowProps) => {
  const isDarkTheme = theme === 'dark';
  const { white, dark } = useColor();

  let newArr: ArrType = [];
  if (hasMenu) {
    newArr = [
      ...namesTd,
      <div className={s.menu}>
        {menuIsVisible && (
          <Menu
            anchor="left"
            button={
              <Button iconButton>
                <CrumbsIcon className={s.iconCrumbs} color={isDarkTheme ? white : dark} />
              </Button>
            }
            className={classNames(s[`menuTableRow-${theme}`])}
            theme={theme}>
            <Label
              theme={theme}
              title={
                <div className={s.removeDeal} onClick={() => onDelete && onDelete()}>
                  <Translated id="app.delete-bet" />
                </div>
              }
            />
          </Menu>
        )}
      </div>,
    ];
  }
  return (
    <tr className={classNames(s.row, variant && s[variant], theme && s[theme])}>
      {(hasMenu ? (newArr as []) : (namesTd as [])).map((nameTd, index) => (
        <td key={index}>{nameTd}</td>
      ))}
    </tr>
  );
};

export default TableRow;
