import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import s from './Tooltip.module.scss';

interface TooltipProps {
  theme?: ThemeType;
  position?: 'top' | 'bottom' | 'left' | 'right';
  toolTipText?: string;
  children?: Children;
  className?: string;
}

const Tooltip = ({
  theme = 'default',
  position = 'top',
  toolTipText = '',
  children,
  className,
}: TooltipProps) => (
  <div className={classNames(s.tooltip, className, s[`tooltip-${position}`])}>
    <span className={classNames(s.tooltipText, s[theme], s[`tooltipText-${position}`])}>
      {toolTipText}
    </span>
    {children}
  </div>
);

export default Tooltip;
