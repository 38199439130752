/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import { ResultList } from 'types/baseTypes';

export const isValidNotEmptyArray = (array: any[]): boolean =>
  !!(array && array?.length && array?.length > 0);

const useInfiniteScroll = <T>(
  useGetDataListQuery: UseQuery<any>,
  { size = 10, page = 0, ...params },
  options?: any,
) => {
  const [localPage, setLocalPage] = useState(page);
  const [groupedData, setGroupedData] = useState<{ [key: number]: T[] }>({});
  const queryResponse = useGetDataListQuery(
    {
      page: localPage,
      size,
      ...params,
    },
    options,
  );

  const { result = [], totalPages = 0 } = (queryResponse?.data as ResultList<T>) || {};
  useEffect(() => {
    if (isValidNotEmptyArray(result)) {
      setGroupedData((previousData) => ({ ...previousData, [localPage]: result }));
    }
  }, [result]);

  const refresh = useCallback(() => {
    setGroupedData({});
    setLocalPage(0);
  }, []);

  const loadMore = () => {
    if (localPage < totalPages) {
      setLocalPage((prevPage) => prevPage + 1);
    }
  };

  // отнимаем 1, т.к. страницы считаются с 0
  const hasMore = localPage < totalPages - 1;
  const combinedData = Object.values(groupedData).flat();

  return {
    combinedData,
    localPage,
    loadMore,
    refresh,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
    hasMore,
  };
};

export default useInfiniteScroll;
