import classNames from 'classnames';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useTranslate from 'hooks/useTranslate.hook';
import mapTransactionsToHistory from 'mappings/mapTransactionsToHistory';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetBalanceHistoryQuery } from 'store/services/apiService';
import useMediaQuery from 'theme/useMediaQuery';

import Spinner from 'components/Simple/Spinner/Spinner';
import TransactionCard from 'components/Simple/TransactionCard';
import Typography from 'components/Simple/Typography';
import formatDate from 'helpers/formatDate';
import { Transaction } from 'types/balance';

import TransactionsWrapper from '../TransactionsWrapper';
import Translated from '../Translated';
import s from './PortfolioTransactionHistory.module.scss';

const PortfolioTransactionHistory = () => {
  const { theme, lang } = useSelector((state: RootState) => state.app);
  const isLaptop = useMediaQuery(1240);
  const {
    combinedData: history,
    loadMore,
    hasMore,
  } = useInfiniteScroll<Transaction>(useGetBalanceHistoryQuery, {
    page: 0,
    size: 10,
    sort: 'createdDate,desc',
  });

  const transactionHistory = history ? mapTransactionsToHistory(history) : [];
  const acrual = useTranslate('freegame.accrual');
  const charging = useTranslate('freegame.charging');
  const predcit = useTranslate('portfolio.predict-btc');
  return (
    <div className={classNames(s.rightBlock, s[`rightBlock-${theme}`])} id="transactionsBlock">
      {!isLaptop && (
        <Typography variant="h1">
          <Translated id="portfolio.transactions" />
        </Typography>
      )}
      <InfiniteScroll
        dataLength={history.length}
        hasMore={hasMore}
        loader={<Spinner />}
        next={loadMore}
        scrollableTarget={isLaptop ? undefined : 'transactionsBlock'}>
        <div>
          {transactionHistory.map((period, periodIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <TransactionsWrapper date={period.date} key={periodIndex}>
              {period.transactions.map((transaction, index) => (
                <TransactionCard
                  actionsBetTitle={Math.sign(transaction.amount) === 1 ? acrual : charging}
                  actionValue={`${Math.abs(transaction.amount)} FreeBet`}
                  content={transaction.info ? predcit : undefined}
                  date={formatDate(transaction.date, 'D MMMM, HH:mm', lang)}
                  dateContent={
                    transaction.info
                      ? `в ${formatDate(
                          transaction.info?.expirationTime,
                          'HH:mm, D MMMM YYYY',
                          lang,
                        )}`
                      : undefined
                  }
                  idDeal={transaction.info?.externalId}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  theme={theme}
                />
              ))}
            </TransactionsWrapper>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default PortfolioTransactionHistory;
