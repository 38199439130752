import React, { useState } from 'react';

import classNames from 'classnames';
import Select, { CSSObjectWithLabel } from 'react-select';

import { SelectOptionsType, ThemeType } from 'types/baseTypes';

import s from './SelectComponentWithCommission.module.scss';

const SelectComponentWithCommission = ({
  disabled,
  theme = 'default',
  options,
  onChange,
}: SelectComponentProps) => {
  const hasOptions = options && options?.length > 1;

  const [value, setValue] = useState(hasOptions ? options[0] : null);

  const customStyles = {
    container: () => ({
      width: '100%',
    }),
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      height: '50px',
      padding: '0 0 0 15px',
      backgroundColor: 'transparent',
      borderRadius: '5px',
      cursor: 'pointer',
      borderColor: theme === 'dark' ? '#2E3138' : '#CFD0D1',
      '&:hover': {
        borderColor: theme === 'dark' ? '#2E3138' : '#CFD0D1',
      },
      display: 'flex',
      width: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      '& div': {
        padding: '8px 15px',
      },
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#101319',
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      margin: '2px 0',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme === 'dark' ? '#2E3138' : '#CFD0D1',
      borderRadius: '5px',
      backgroundColor: theme === 'dark' ? '#171A22' : '#FFF',
      boxShadow: theme === 'dark' ? 'none' : '0px 4px 20px rgba(0, 0, 0, 0.1)',
    }),
    menuList: () => ({
      maxHeight: '100%',
      padding: '15px 0',
    }),
    menuitem: () => ({
      justifyContent: 'start',
    }),
    option: (provided: CSSObjectWithLabel, state: { isSelected: boolean }) => ({
      ...provided,
      color: state.isSelected ? '#898A8E' : '#101319',
      padding: '5px 15px 5px 23px',
      fontSize: '13px',
      backgroundColor: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme === 'dark' ? '#5f5f5f' : '#e5e3e3',
      },
    }),
    dropdownIndicator: (base: any, state: any) => ({
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 10 }),
  };

  const formatOptionLabel = ({ label, customAbbreviation, currency }: SelectOptionsType) => (
    <div className={classNames(s.optionLabelWrapper)}>
      <div
        className={classNames(
          s.label,
          theme === 'default' ? s.commissionLabelDefault : s.commissionLabelDark,
        )}>
        {label}
      </div>
      <div
        className={classNames(
          s.abbreviation,
          theme === 'default' ? s.abbreviationDefault : s.abbreviationDark,
        )}>{`${customAbbreviation} ${currency}`}</div>
    </div>
  );

  const handleChange = (val: any) => {
    if (onChange) {
      setValue(val);
      onChange(val);
    }
  };

  const filteredOptions = options?.filter((obj) => obj.label !== value?.label);

  return (
    <div className={classNames(s.wrapper, disabled && s.disabled, s[theme])}>
      <Select
        defaultValue={hasOptions ? options[0] : null}
        formatOptionLabel={formatOptionLabel}
        id="select-commission-label"
        isSearchable={false}
        menuPlacement="auto"
        menuPortalTarget={document.querySelector('body')}
        onChange={handleChange}
        options={filteredOptions}
        placeholder=""
        styles={customStyles}
      />
    </div>
  );
};

type SelectComponentProps = {
  theme?: ThemeType;
  disabled?: boolean;
  options?: SelectOptionsType[];
  onChange?: (value: any) => void;
};

export default SelectComponentWithCommission;
