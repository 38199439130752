import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import useMediaQuery from '../../../theme/useMediaQuery';
import { CloseIcon } from '../../Icons';
import Button from '../../Simple/Button';
import Typography from '../../Simple/Typography';
import s from './Notification.module.scss';

interface NotificationProps {
  theme?: ThemeType;
  handleClose?: () => void;
  buttonFunc?: () => void;
  cookieNotification?: boolean;
  title?: string;
  subTitle?: string;
  titleButton?: string;
  reference?: React.RefObject<HTMLDivElement>;
  heightBlock?: string | number | null;
  className?: string;
}

const Notification = ({
  theme = 'default',
  handleClose,
  buttonFunc,
  cookieNotification = true,
  title = '',
  subTitle,
  titleButton = '',
  reference,
  heightBlock,
  className,
}: NotificationProps) => {
  const [show, setShow] = useState(false);
  const isLaptop = useMediaQuery(1024);

  const position = isLaptop ? 10 : 32;
  const plusPosition = isLaptop ? 25 : 45;

  const bottomIndent = `${String(heightBlock ? +heightBlock + plusPosition : position)}px`;

  const closeAnimation = () => {
    setShow(false);
    if (handleClose) {
      handleClose();
    }
  };

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div
      className={classNames(s.mobileWrapper, show ? s.show : null, className)}
      ref={reference}
      style={{ bottom: bottomIndent }}>
      <div
        className={classNames(s.wrapper, s[theme], {
          [s.notification]: !cookieNotification,
        })}>
        <div className={s.titleBlock}>
          <Typography variant="h2">{title}</Typography>
          <p>{subTitle}</p>
        </div>
        {titleButton && (
          <Button className={s.btn} onClick={buttonFunc} size="sm">
            {titleButton}
          </Button>
        )}
        <div className={s.closeBtn} onClick={closeAnimation}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default Notification;
