import React from 'react';

const BinanceIcon = () => (
  <svg fill="none" height="18" viewBox="0 0 90 18" width="90" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2658_2928)">
      <path
        d="M5.43554 7.56375L8.93692 4.06827L12.4401 7.5656L14.4775 5.5316L8.93692 0L3.39819 5.52976L5.43554 7.56375Z"
        fill="#F3BA2F"
      />
      <path
        d="M3.99678 8.99978L1.95947 6.96582L-0.0779371 8.99988L1.95937 11.0338L3.99678 8.99978Z"
        fill="#F3BA2F"
      />
      <path
        d="M5.4356 10.4363L8.93698 13.9318L12.4401 10.4346L14.4785 12.4674L14.4776 12.4686L8.93698 18L3.39811 12.4704L3.39526 12.4676L5.4356 10.4363Z"
        fill="#F3BA2F"
      />
      <path
        d="M15.9147 11.0348L17.9521 9.00073L15.9148 6.96677L13.8774 9.00083L15.9147 11.0348Z"
        fill="#F3BA2F"
      />
      <path
        d="M11.0036 8.99905H11.0044L8.93704 6.93506L7.40921 8.46038H7.40907L7.23363 8.63567L6.8715 8.9972L6.86865 9.00005L6.8715 9.00303L8.93704 11.0652L11.0044 9.00118L11.0054 9.00005L11.0036 8.99905Z"
        fill="#F3BA2F"
      />
      <path
        d="M21.1282 4.36157H25.5593C26.6591 4.36157 27.4907 4.64335 28.0543 5.2069C28.4905 5.64336 28.7085 6.18431 28.7085 6.8296V6.8569C28.7085 7.12958 28.6748 7.37055 28.6068 7.57954C28.5391 7.78881 28.4485 7.97718 28.3353 8.14537C28.2224 8.31369 28.0911 8.46141 27.9418 8.5885C27.7924 8.71589 27.632 8.82507 27.4601 8.91577C28.0129 9.12504 28.4481 9.40895 28.7653 9.76807C29.0825 10.1273 29.2412 10.6251 29.2412 11.2613V11.2884C29.2412 11.7249 29.1572 12.1066 28.989 12.434C28.8209 12.7611 28.5799 13.0339 28.2662 13.2522C27.9525 13.4704 27.5753 13.6339 27.1344 13.7431C26.6935 13.8521 26.2049 13.9066 25.6688 13.9066H21.1282V4.36157ZM25.1163 8.22043C25.5808 8.22043 25.9496 8.1411 26.2226 7.98187C26.4957 7.82279 26.6323 7.56589 26.6323 7.21147V7.18417C26.6323 6.86614 26.5138 6.62303 26.2771 6.45471C26.0403 6.28652 25.6988 6.20236 25.2528 6.20236H23.1768V8.22043H25.1163ZM25.6764 12.0659C26.1407 12.0659 26.5047 11.982 26.769 11.8137C27.033 11.6455 27.1652 11.3841 27.1652 11.0295V11.0024C27.1652 10.6842 27.0421 10.432 26.7963 10.2455C26.5504 10.0594 26.1544 9.96611 25.608 9.96611H23.1768V12.0661H25.6764V12.0659Z"
        fill="#F3BA2F"
      />
      <path d="M31.8801 4.36157H33.9837V13.9069H31.8801V4.36157Z" fill="#F3BA2F" />
      <path
        d="M37.1697 4.36157H39.1089L43.589 10.2388V4.36157H45.6651V13.9069H43.876L39.2456 7.83885V13.9069H37.1697V4.36157Z"
        fill="#F3BA2F"
      />
      <path
        d="M52.0334 4.29346H53.9728L58.0706 13.9068H55.8715L54.9973 11.7661H50.9543L50.0803 13.9068H47.9355L52.0334 4.29346ZM54.2461 9.91136L52.9757 6.81608L51.7058 9.91136H54.2461Z"
        fill="#F3BA2F"
      />
      <path
        d="M60.3411 4.36157H62.2807L66.7605 10.2388V4.36157H68.8366V13.9069H67.0475L62.4171 7.83885V13.9069H60.3411V4.36157Z"
        fill="#F3BA2F"
      />
      <path
        d="M76.4066 14.0704C75.7052 14.0704 75.0544 13.943 74.4533 13.6887C73.8522 13.4344 73.3333 13.0865 72.8963 12.6455C72.4591 12.2046 72.1175 11.6841 71.872 11.0842C71.6261 10.4841 71.5032 9.8432 71.5032 9.16136V9.1342C71.5032 8.45237 71.6261 7.81389 71.872 7.21835C72.1176 6.62295 72.4591 6.1002 72.8963 5.65024C73.3332 5.20028 73.8569 4.84557 74.4668 4.58654C75.0768 4.32751 75.751 4.198 76.4885 4.198C76.9344 4.198 77.3418 4.23454 77.7106 4.30704C78.0794 4.37997 78.4139 4.47977 78.7147 4.60701C79.0153 4.7344 79.2927 4.88893 79.548 5.07062C79.8025 5.2526 80.0395 5.45249 80.258 5.67071L78.9198 7.21153C78.5459 6.8753 78.166 6.61144 77.7791 6.42065C77.3919 6.22986 76.9573 6.13432 76.4747 6.13432C76.074 6.13432 75.7029 6.21166 75.3617 6.3662C75.0202 6.52073 74.7264 6.73427 74.4805 7.00695C74.2349 7.27963 74.0436 7.59566 73.9068 7.95464C73.7705 8.3139 73.7023 8.69789 73.7023 9.10691V9.13406C73.7023 9.54308 73.7705 9.92963 73.9068 10.293C74.0436 10.6568 74.2322 10.9749 74.4738 11.2475C74.7149 11.5202 75.0064 11.7363 75.3477 11.8954C75.6895 12.0546 76.065 12.1339 76.4747 12.1339C77.0212 12.1339 77.4829 12.034 77.8608 11.834C78.2388 11.6342 78.6146 11.3614 78.9878 11.0158L80.3264 12.3658C80.0805 12.6295 79.8251 12.866 79.5616 13.0748C79.2974 13.2841 79.0083 13.4635 78.694 13.6136C78.38 13.7635 78.036 13.8773 77.6631 13.9544C77.2894 14.0316 76.8707 14.0704 76.4066 14.0704Z"
        fill="#F3BA2F"
      />
      <path
        d="M82.802 4.36157H90V6.2298H84.8782V8.16612H89.3855V10.0342H84.8782V12.0388H90.0686V13.9069H82.802V4.36157Z"
        fill="#F3BA2F"
      />
    </g>
    <defs>
      <clipPath id="clip0_2658_2928">
        <rect fill="white" height="18" width="90" />
      </clipPath>
    </defs>
  </svg>
);

export default BinanceIcon;
