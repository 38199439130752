/* eslint-disable eqeqeq */
/* eslint-disable no-sparse-arrays */
import { useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import s from './index.module.scss';


const mock = [{
    time: '1M',
    val: 1,
  },
  {
    time: '5M',
    val: 5,
  },
  {
    time: '15M',
    val: 15,
  },
  {
    time: '30M',
    val: 30,
  },
  {
    time: '1H',
    val: 60,
  },
  {
    time: '1D',
    val: 'D',
  },
  {
    time: '1W',
    val: 'W',
  },
  ,
  {
    time: '1M',
    val: 'M',
  },
];

export interface IChartRadioGroup {
  onChange: (val: number | string) => void;
}

const ChartRadioGroup: React.FC<IChartRadioGroup> = ({ onChange }) => {
  const [selected, setSelected] = useState<number | string>(1);
  const theme = useSelector((state: RootState) => state.app.theme);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSelected(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div className={s.wrapper}>
      {mock.map((el: typeof mock[0], idx: number) => (
        <label
          // eslint-disable-next-line react/no-array-index-key
          className={classNames(s.container, {
            [s.dark]: theme === 'dark',
            [s.default]: theme === 'default',
            [s.selected]: selected == el?.val,
          })}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}>
          {el?.time}
          <input name="radio" onChange={handleChange} type="radio" value={el?.val} />
          <span className="checkmark" />
        </label>
      ))}
    </div>
  );
};

export default ChartRadioGroup;
