import s from './Toast.module.scss';

interface ToastContentProps {
  title: string;
  text: string | JSX.Element;
  button?: JSX.Element;
}

const ToastContent = ({ title, text, button }: ToastContentProps) => (
  <div className={s.toastContent}>
    <h3 className={s.toastTitle}>{title}</h3>
    <div className={s.toastText}>{text}</div>
    {button && button}
  </div>
);

export default ToastContent;
