import React from 'react';

import { IconsTypes } from 'types/baseTypes';

const JackpoolIcon = ({ height = '25', width = '25', color = '#898A8E' }: IconsTypes) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 25 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <mask
      height="26"
      id="mask0_92_97"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'luminance' }}
      width="25"
      x="0"
      y="0">
      <path d="M25 0.569397H0V25.5694H25V0.569397Z" fill="white" />
    </mask>
    <g mask="url(#mask0_92_97)">
      <mask
        height="26"
        id="mask1_92_97"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="25"
        x="0"
        y="0">
        <path d="M25 0.569397H0V25.5694H25V0.569397Z" fill="white" />
      </mask>
      <g mask="url(#mask1_92_97)">
        <path
          d="M21.875 2.1319C19.1406 2.1319 18.75 4.47565 18.75 5.2569C18.75 6.03815 18.75 6.8194 19.5312 6.8194C20.3125 6.8194 20.3125 6.03815 20.3125 5.2569C20.3125 4.47565 20.7031 3.6944 21.875 3.6944C23.0469 3.6944 23.4375 4.47565 23.4375 5.2569C23.4375 9.16315 18.75 11.5069 16.4062 11.5069H8.59375C6.25 11.5069 1.5625 9.16315 1.5625 5.2569C1.5625 4.47565 1.95312 3.6944 3.125 3.6944C4.29688 3.6944 4.6875 4.47565 4.6875 5.2569C4.6875 6.03815 4.6875 6.8194 5.46875 6.8194C6.25 6.8194 6.25 6.03815 6.25 5.2569C6.25 4.47565 5.85938 2.1319 3.125 2.1319C1.17188 2.1319 0 3.6944 0 5.2569C0 9.9444 5.46875 13.0694 8.59375 13.0694H16.4062C19.5312 13.0694 25 9.9444 25 5.2569C25 3.6944 23.8281 2.1319 21.875 2.1319Z"
          fill="#CC991A"
        />
        <path
          d="M19.5312 2.1319C19.5312 1.35026 19.5312 0.569397 18.75 0.569397C17.9688 0.569397 7.03125 0.569397 6.25 0.569397C5.46875 0.569397 5.46875 1.35026 5.46875 2.1319C5.46875 15.4132 10.9375 13.8506 10.9375 15.4132V16.9756C10.9375 18.5382 8.59375 17.7569 8.59375 19.3194H16.4062C16.4062 17.7569 14.0625 18.5382 14.0625 16.9756V15.4132C14.0625 13.8506 19.5312 15.4132 19.5312 2.1319Z"
          fill="#DFBD68"
        />
        <path
          d="M19.5312 20.8819H5.46875C5.0375 20.8819 4.6875 21.2315 4.6875 21.6632V24.7882C4.6875 25.2194 5.0375 25.5694 5.46875 25.5694H19.5312C19.9625 25.5694 20.3125 25.2194 20.3125 24.7882V21.6632C20.3125 21.2315 19.9625 20.8819 19.5312 20.8819Z"
          fill="#E3AC21"
        />
        <path
          d="M15.625 22.4444H9.375C8.94353 22.4444 8.59375 22.7942 8.59375 23.2256C8.59375 23.6571 8.94353 24.0069 9.375 24.0069H15.625C16.0565 24.0069 16.4062 23.6571 16.4062 23.2256C16.4062 22.7942 16.0565 22.4444 15.625 22.4444Z"
          fill="#AD8113"
        />
        <path
          clipRule="evenodd"
          d="M16.4062 19.3194H8.59375C8.59375 19.3194 6.25 19.3194 6.25 20.8819H18.75C18.75 19.3194 16.4062 19.3194 16.4062 19.3194ZM10.9375 16.9756H14.0625C14.4937 16.9756 14.8438 16.6256 14.8438 16.1944C14.8438 15.7628 14.4937 15.4132 14.0625 15.4132H10.9375C10.5062 15.4132 10.1562 15.7628 10.1562 16.1944C10.1562 16.6256 10.5062 16.9756 10.9375 16.9756Z"
          fill="#F2BA2F"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default JackpoolIcon;
