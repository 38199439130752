const en = {
  'app.lange.en': 'English',
  'app.lange.ru': 'Russian',
  'app.lang': 'English',
  'app.hide': 'Hide',
  'app.your-email': 'Your email',
  'app.username': 'Username',
  'app.wallet': 'Wallet',
  'app.new-password': 'New password',
  'app.at': 'at',
  'app.delete-bet': 'Delete bet',
  'app.show-more': 'Show more',
  'app.deal-details': 'Deal details',
  'sidebar.finance-deals': 'Finance deals',
  'sidebar.finance-deals.crypto': 'Crypto',
  'sidebar.finance-deals.stocks': 'Stocks',
  'sidebar.finance-deals.bonds': 'Bonds',
  'sidebar.finance-deals.currency': 'Currency',
  'sidebar.finance-deals.metals': 'Metals',
  'sidebar.finance-deals.product': 'Product',
  'sidebar.finance-deals.binary-options': 'Up / Down',
  'sidebar.finance-deals.lottery': 'Darts',
  'sidebar.finance-deals.jackpot': 'Jack Pool',
  'sidebar.finance-deals.make-bet': 'Make a bet',

  'header.main': 'Main',
  'header.my-deals': 'My Deals',
  'header.community': 'Community',
  'header.auth': 'Authorization',
  'header.burger.dark-theme': 'Dark theme',
  'header.burger.my-transactions': 'My transactions',
  'header.burger.my-portfolio': 'Account',
  'header.burger.copy-address': 'Copy address',
  'header.burger.log-out': 'Log out',
  'header.burger.edit-profile': 'Edit profile',

  'my-deals': 'My deals',
  'my-deals.finance-deals': 'Finance deals',
  'my-deals.search-for-deals': 'Search for a deal',
  'my-deals.high-liquidity': 'High liquidity',
  'my-deals.new': 'New',
  'my-deals.private': 'Private',
  'my-deals.will-the-btc': 'Will the BTC price be higher than xxxxx.xx at 12:00, 1 august 2022',
  'my-deals.common--pool-done': 'Common pool Done',
  'my-deals.bets': 'bets',

  portfolio: 'Portfolio',
  'portfolio.my-deals': 'My Deals',
  'portfolio.attempt-value': 'Attempts count',
  'portfolio.win': 'Win',
  'portfolio.all-deals': 'All Deals',
  'portfolio.attempts-count': 'Attempts count',
  'portfolio.finance': 'Finance',
  'portfolio.binary-options': 'Up / Down',
  'portfolio.bet-amount': 'Bet amount',
  'portfolio.chose-outcome': 'Chose outcome',
  'portfolio.will-be-higher': 'Will be higher',
  'portfolio.lower': 'lower',
  'portfolio.get': 'Get',
  'portfolio.outcome': 'Outcome',
  'portfolio.received': 'Received',
  'portfolio.date-time': 'Date and Time',
  'portfolio.transactions': 'Transactions',
  'portfolio.adding-bet': 'Adding a bet',
  'portfolio.will-the-btc': 'Will the BTC price be higher than $xx.xxxx',
  'portfolio.deal-id': 'Deal ID',
  'portfolio.deleting-bet': 'Deleting a bet',
  'portfolio.predict-decrease-increase': 'Predict decrease or increase of the exchange rate',
  'portfolio.return-bet': 'Return of the bet',
  'portfolio.export-report': 'An error occurs while exporting a report',
  'portfolio.deal-cancel-error': 'An error occurred while canceling a bet',
  'portfolio.store-data':
    'We always store all the data of any closed transaction. In the results, you can find a list of rates of all users involved in this transaction, as well as the final rate at the time of the transaction expiration.',
  'portfolio.download-csv': 'Download csv',
  'portfolio.transactions-result': 'Transaction results',
  'portfolio.final-rate': 'Final rate',
  'portfolio.predict-btc': 'Predict the BTC rate',

  'states.make-bet': 'Make a bet',
  'states.higher': 'Higher',
  'states.lower': 'Lower',
  'states.fee': 'Fee',
  'states.write-off-bet': 'Write off with the bet',
  'states.deal-ratio': 'Deal ratio',
  'states.maximum-win': 'Maximum win',
  'states.bets-accepted': 'Bets accepted',
  'states.bet-amount': 'Bet amount',
  'states.funds-pending': 'Funds pending',
  'states.will-be-accepted': 'Will be accepted for the deal',
  'states.your-bet': 'Your bet',
  'states.deal-done': 'Deal completed',
  'states.error': 'Error',
  'states.error-occured': 'Произошла ошибка при размещении ставки',

  'private-deal': 'This deal is private',
  'private-deal.only': 'Only those who has the link can view and participate in the deal',
  'private-deal.cop-link': 'Copy the link',

  'options.five-minute-game': 'FREE GAME for 5 minutes',
  'options.weekly-game': 'FREE GAME with daily prize',
  'options.currency-pair-accepting': 'Currency pair accepting bets 12 minutes left ',
  'options.predict-increase-decrease': 'Predict increase or decrease of the exchange rate',
  'options.account': 'Account',
  'options.last-bets': 'Last bets',
  'options.my-bets': 'My bets',

  'options.predict-on-date': 'Predict the BTC rate on',
  'options.accepting-bets': 'Accepting bets',
  'options.currency-pair': 'currency pair',
  'options.price-bet': 'price of 1 bet',
  'options.waiting-result': 'Waiting for the result',
  'options.deal-closed': 'Deal closed',
  'options.pool-distributed': 'The pool is distributed',
  'options.meaning': 'Meaning',
  'options.make-bet': 'Make bet',
  'options.price-will': 'The price will',
  'options.data-source': 'Data source',

  'notification.deal-completed': 'Deal completed',
  'notification.update-page': 'Update the page to see a new deal',
  'notification.update': 'Update',
  'notification.collection-cookie': 'Cookie collection notification',
  'notification.we-use-cookie': 'We use cookie files to improve website quality',
  'notification.cookie-ok': 'Ok',

  'lottery.predict-price': 'Predict the price of BTC in 5 minutes',
  'jackpot.raffle-date': 'Raffle date',

  'dashboard.popular-assets': 'Popular assets',
  'dashboard.popular-deals': 'Popular deals',
  'dashboard.new-deals': 'New deals',
  'dashboard.view-deals': 'Viewed deals',
  'dashboard.bets-acception': 'Bets are acception',

  //   FREEGAME
  'freegame.be-part.header': "Be part of P2PBet's $100 DAILY RAFFLE!",
  'freegame.be-part.text1':
    'Every day we raffle 100$ between the Free Game users. Use your 3 attempts to forecast the BTC exchange rate in the given time. The entrant whose result will be the closest or the same as the BTC exchange rate at the time of expiry will receive the prize!',
  'freegame.be-part.text2-part1':
    'But there is a condition - to take the prize you must be followed on any of our social media. And remember to include your address of the USDT TRC20 in the ',
  'freegame.be-part.text2-part2': 'profile editing ',
  'freegame.be-part.text2-part3': 'section. ',

  'freegame.daily-prize': 'FREE GAME with daily prize',
  'freegame.five-minute': 'FREE GAME for 5 minutes',

  'freegame.what-is': 'What is Free Game?',
  'freegame.desc':
    'Free Game - this is a platform fully imitating P2PBET functionality, but without the use of the MetaMask wallet. Accept any financial tools available on the P2PBet, on the FreeGame platform among all registered users will drop real prizes every day! We created FreeGame so that you can participate in financial betting, earn money without investing, only on your calculation and intuition. Good luck!',

  'freegame.amount-attempts': 'The amount of attempts:',
  'freegame.predict-btc':
    'Predict the BTC exchange rate at the given time. The participant whose outcome will be the nearest or the same as the actual BTC/USDT exchange rate at the time of expiration on Binance will be the winner.',
  'freegame.view-list': 'View a list of all bets.',
  'freegame.view-list-my-bets': 'View my bets',
  'freegame.accrual': 'Accrual',
  'freegame.charging': 'Charging',
  'freegame.main-data': 'Main data',
  'freegame.payment-details': 'Payment details',
  'freegame.login': 'Log in',
  'freegame.username-email': 'Username or Email',
  'freegame.do-have-account': 'Do you have an account? Log in',
  'freegame.signup': 'Sign up',
  'freegame.leave': 'Leave Free Game mode',
  'freegame.back': 'Back',
  'freegame.save-settings': 'Save settings',
  'freegame.repeat-password': 'Repeat password',
  'freegame.safety': 'Safety',
  'freegame.setting-saved': 'Settings have been saved',
  'freegame.date-updated': 'The information has been successfully updated.',
  'freegame.eroor-create': 'An error occurred while saving data',
  'freegame.social-networks': 'Social networks',
  'freegame.loss': 'Loss',
  'freegame.rateTitle': 'Current rate',
};

export default en;
