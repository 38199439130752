import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import keycloak from 'keycloak';

import {
  AuctionBet,
  AuctionBetParams,
  AuctionLeaderParams,
  CancelBetParams,
  ExportReportParams,
  PersonalAuctionBet,
  TakePrizeParams,
  UserBetData,
  UserBetParams,
} from 'types/auctionBet';
import { Transaction } from 'types/balance';
import { ResultList } from 'types/baseTypes';
import { Event } from 'types/event';
import { AuctionJoinsParams, IJoin, JoinInfo } from 'types/join';
import { UserData } from 'types/user';

export const API_NAME = 'freeBetApi';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    if (keycloak.token) {
      headers.set('authorization', `Bearer ${keycloak.token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  // unauthorized
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        await keycloak.updateToken(30);
        // retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } catch {
        console.error(
          `Request ${result.meta?.request.url} is protected. User must be authenticated`,
        );
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiService = createApi({
  reducerPath: API_NAME,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Joins', 'Bets'],
  endpoints: (builder) => ({
    getTime: builder.query({
      query: () => ({
        url: '/user-management/api/v1/util/date',
        method: 'GET',
      }),
    }),
    getJoins: builder.query<ResultList<IJoin>, AuctionJoinsParams>({
      query: (data) => ({
        url: '/core/public/api/v1/auction/bet/joins',
        method: 'POST',
        body: data,
      }),
      providesTags: ['Joins'],
    }),
    getAuctionBets: builder.query<ResultList<AuctionBet>, AuctionBetParams>({
      query: (data) => ({
        url: '/core/public/api/v1/auction/bet',
        method: 'POST',
        body: data,
      }),
    }),
    getClientInfo: builder.query<JoinInfo[], string>({
      query: (username) => ({
        url: `/core/public/api/v1/client/${username}/info`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getEventsList: builder.query({
      query: () => ({
        url: '/core/public/api/v1/events',
        method: 'POST',
        body: {
          page: 0,
          size: 10,
        },
      }),
    }),
    getEventById: builder.query<Event, string>({
      query: (id) => ({
        url: `/core/public/api/v1/events/${id}`,
        method: 'GET',
      }),
    }),
    getPersonalBets: builder.query<ResultList<PersonalAuctionBet>, AuctionBetParams>({
      query: (data) => ({
        url: '/core/public/api/v1/auction/bet/personal',
        method: 'POST',
        body: data,
      }),
      providesTags: ['Bets'],
    }),
    getBetLeader: builder.query<IJoin, AuctionLeaderParams>({
      query: ({ id, value }) => ({
        url: `/core/public/api/v1/auction/bet/FREE/${id}/leader`,
        method: 'GET',
        params: {
          value,
        },
      }),
    }),
    placeBet: builder.mutation<UserBetData, UserBetParams>({
      query: (data) => ({
        url: '/auction-free/api/v1/free/auction/user/join',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User', 'Joins', 'Bets'],
    }),
    cancelBet: builder.mutation<UserBetData, CancelBetParams>({
      query: (data) => ({
        url: '/auction-free/api/v1/free/auction/user/cancel',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User', 'Joins', 'Bets'],
    }),
    takePrize: builder.mutation<UserBetData, TakePrizeParams>({
      query: (data) => ({
        url: '/auction-free/api/v1/free/auction/user/prize',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User', 'Joins', 'Bets'],
    }),
    exportBetReport: builder.mutation<null, ExportReportParams>({
      query: ({ betId, value }) => ({
        url: `/core/public/api/v1/auction/free/bet/FREE/${betId}/report`,
        method: 'GET',
        params: { value },
        responseHandler: async (response) => {
          if (response.status === 200 && response.url) {
            const tempLink = document.createElement('a');
            tempLink.href = response.url;
            tempLink.click();
          }
        },
        cache: 'no-cache',
      }),
    }),
    getBalance: builder.query({
      query: () => ({
        url: '/user-management/api/v1/users/balance',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getBalanceHistory: builder.query<
      ResultList<Transaction>,
      { pageSize: number; pageNumber: number }
    >({
      query: (params) => ({
        url: '/user-management/api/v1/users/balance/history',
        method: 'GET',
        params,
      }),
      providesTags: ['User'],
    }),
    getUserData: builder.query<UserData, null>({
      query: () => ({
        url: '/user-management/api/v1/users',
        method: 'GET',
        mode: 'cors',
      }),
    }),
    updatePassword: builder.mutation<null, { password: string }>({
      query: ({ password }) => ({
        url: '/user-management/api/v1/users/password',
        method: 'POST',
        body: {
          password,
        },
      }),
    }),
    updateUserData: builder.mutation<
      null,
      {
        email: string;
        rewardAddress: string;
        username: string;
        socialNetworks: {
          discord: string;
          telegram: string;
          twitter: string;
        };
      }
    >({
      query: ({ email, rewardAddress, username, socialNetworks }) => ({
        url: '/user-management/api/v1/users',
        method: 'PATCH',
        headers: { 'content-type': 'application/json' },
        body: {
          email,
          rewardAddress,
          username,
          socialNetworks,
        },
      }),
    }),
    registerUser: builder.mutation({
      query: (data) => ({
        url: '/user-management/api/v1/users/register',
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetTimeQuery,
  useGetJoinsQuery,
  usePlaceBetMutation,
  useCancelBetMutation,
  useGetBalanceHistoryQuery,
  useGetPersonalBetsQuery,
  useGetAuctionBetsQuery,
  useGetUserDataQuery,
  useUpdatePasswordMutation,
  useTakePrizeMutation,
  useGetClientInfoQuery,
  useGetBetLeaderQuery,
  useLazyGetBetLeaderQuery,
  useLazyGetJoinsQuery,
  useLazyGetAuctionBetsQuery,
  useLazyGetBalanceQuery,
  useLazyGetEventByIdQuery,
  useLazyGetUserDataQuery,
  useLazyGetTimeQuery,
  useRegisterUserMutation,
  useUpdateUserDataMutation,
  useExportBetReportMutation,
} = apiService;
