import React from 'react';

type OptionalIconProps = {
  size?: string;
  className?: string;
  color?: string;
};

const DartsIcon = ({ size = '25', className, color = '#898A8E' }: OptionalIconProps) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox="0 0 25 26"
    width={size}
    xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M7.96572 10.3138C7.62107 9.83726 7.29001 9.36497 7.01435 8.96394L7.01464 8.96365C6.84596 9.01853 6.67176 9.05277 6.49452 9.06837C5.57891 10.4354 5.04395 12.0775 5.04395 13.8428C5.04395 18.5868 8.90428 22.4458 13.6477 22.4458C18.3911 22.4458 22.2503 18.5864 22.2501 13.8428C22.2501 9.09924 18.3912 5.23969 13.647 5.23969C11.809 5.23969 10.1052 5.82068 8.70546 6.80679C8.69387 6.98905 8.6619 7.17157 8.60626 7.35056C8.99901 7.61995 9.46249 7.94296 9.93124 8.28105C10.9946 7.56838 12.273 7.15148 13.647 7.15148C17.337 7.15148 20.3386 10.1531 20.3386 13.8431C20.3386 17.5331 17.3369 20.5346 13.647 20.5346C9.95702 20.5346 6.95545 17.5329 6.95545 13.8431C6.95545 12.5486 7.32653 11.3391 7.96572 10.3138ZM13.7135 14.654C13.686 14.654 13.6581 14.6522 13.6304 14.6482L13.6304 14.6482C13.5614 14.6384 12.1987 14.4337 10.7154 13.3615C10.6897 13.5189 10.6729 13.679 10.6729 13.8436C10.6729 15.4861 12.0044 16.8175 13.6468 16.8175C15.2892 16.8175 16.6207 15.4861 16.6207 13.8436C16.6207 12.2012 15.2892 10.8697 13.6468 10.8697C13.4036 10.8697 13.1682 10.9022 12.9417 10.9573C14.079 12.4792 14.295 13.903 14.3048 13.9738C14.331 14.1593 14.2686 14.3466 14.1357 14.479C14.0231 14.5918 13.8708 14.654 13.7135 14.654Z"
      fill={color}
      fillRule="evenodd"
      opacity="0.3"
    />
    <path
      clipRule="evenodd"
      d="M5.99454 6.36269L5.97214 6.34029C4.19578 4.56347 2.26664 5.06322 2.85925 5.65609L5.27983 8.07666C5.77742 8.57377 6.5841 8.61004 7.22521 8.21377C7.94687 9.27569 9.36503 11.3101 10.1697 12.1148C11.8501 13.7952 13.7138 14.0565 13.7138 14.0565C13.7138 14.0565 13.4524 12.1931 11.772 10.5127C10.9609 9.70137 8.91817 8.28268 7.86148 7.56734C8.24122 6.93029 8.19913 6.13871 7.70846 5.64804L5.28788 3.22746C4.69529 2.6348 4.26226 4.63041 5.99454 6.36269Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default DartsIcon;
