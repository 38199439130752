/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';
import dayjs from 'dayjs';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useTranslate from 'hooks/useTranslate.hook';
import Cookies from 'js-cookie';
import mapJoinsToTable from 'mappings/mapJoinsToTable';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import {
  useGetJoinsQuery,
  useGetUserDataQuery,
  useLazyGetAuctionBetsQuery,
  useLazyGetBetLeaderQuery,
  useLazyGetJoinsQuery,
  usePlaceBetMutation,
} from 'store/services/apiService';
import {
  FreegameBetDuration,
  FreegameBetStatus,
  FreegameMode,
  setActiveBet,
  setBetValue,
  setMode,
} from 'store/slices/freegameSlice';

import BetStatusInfo from 'components/Containers/BetStatusInfo/BetStatusInfo';
import FreegameChart from 'components/Containers/FreegameChart/FreegameChart';
import JoinTable from 'components/Containers/JoinTable/JoinTable';
import Layout from 'components/Containers/Layout';
import Modal from 'components/Containers/Modal';
import PlaceYourBet from 'components/Containers/PlaceYourBet';
import QuestionsBlock from 'components/Containers/QuestionsBlock';
import StringTabContent from 'components/Containers/StringTabContent';
import { TableTd } from 'components/Containers/Table/TableRow/TableRow';
import Toast from 'components/Containers/Toast/Toast';
import ToastContent from 'components/Containers/Toast/ToastContent';
import Translated from 'components/Containers/Translated';
import { BtcIcon } from 'components/Icons';
import CrownIcon from 'components/Icons/CrownIcon';
import Badge from 'components/Simple/Badge';
import Button from 'components/Simple/Button';
import Tabs from 'components/Simple/Tabs';
// eslint-disable-next-line import-helpers/order-imports
import TOTAL_ATTEMPTS from 'helpers/constants';
import formatDate from 'helpers/formatDate';
import { IJoin } from 'types/join';
import { BetStatus, JoinStatus } from 'types/statuses';

import useMediaQuery from '../../theme/useMediaQuery';
import s from './freegame.module.scss';

const time = new Date();
time.setSeconds(time.getSeconds() + 600);

const Freegame = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const theme = useSelector((state: RootState) => state.app.theme);
  const now = useSelector((state: RootState) => state.app.now);

  const { mode, activeBet, betValue, betStatus, currentRate } = useSelector(
    (state: RootState) => state.freegame,
  );
  const currentRateRef = useRef('');
  const isLeaderLoaded = useRef(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [leader, setLeader] = useState<IJoin | null>();
  const refreshTimer = useRef<NodeJS.Timer | null>(null);
  const handleOpenBottomSheet = () => {
    toast.dismiss();
    setOpenBottomSheet((prevState) => !prevState);
  };
  const isLaptop = useMediaQuery(1024);
  const { data: userData } = useGetUserDataQuery(null, {
    skip: !keycloak.authenticated,
  });

  const [getPersonalJoins, { data: personalJoins, isFetching: isPersonalJoinsFetching }] =
    useLazyGetJoinsQuery();
  const [getBets, { data: bets }] = useLazyGetAuctionBetsQuery();
  const [placeBet, { isLoading: isPlacingBet }] = usePlaceBetMutation();
  const [getLeader] = useLazyGetBetLeaderQuery();

  const {
    combinedData: myJoins,
    loadMore: loadMoreMyJoins,
    hasMore: hasMoreMyJoins,
    refresh: refreshMyJoins,
  } = useInfiniteScroll<IJoin>(
    useGetJoinsQuery,
    {
      auctionBet: activeBet?.id,
      client: userData?.username,
      page: 0,
      size: 5,
      executionType: 'FREE',
      periodFilter: [
        {
          period:
            mode === FreegameMode.short ? FreegameBetDuration.short : FreegameBetDuration.long,
          condition: 'EQUALS',
        },
      ],
      sort: {
        direction: 'DESC',
        property: 'createdDate',
      },
    },
    {
      skip: !(activeBet && userData),
    },
  );
  const { lang } = useSelector((state: RootState) => state.app);
  const myBets = useTranslate('options.my-bets');
  const lastBets = useTranslate('options.last-bets');
  const makeBets = useTranslate('options.make-bet');
  const labelRangeSlider = useTranslate('options.price-will');
  const signup = useTranslate('freegame.signup');
  const tooltipPrizeLabel = useTranslate('states.maximum-win');
  const yourBet = useTranslate('states.your-bet');
  const willBeAccepted = useTranslate('states.will-be-accepted');
  const betsAccepted = useTranslate('states.bets-accepted');
  const dealDone = useTranslate('states.deal-done');
  const errorText = useTranslate('states.error');
  const errorOccured = useTranslate('states.error-occured');
  const updateText = useTranslate('notification.update');
  const updatePageText = useTranslate('notification.update-page');

  const {
    combinedData: allJoins,
    loadMore: loadMoreAllJoins,
    hasMore: hasMoreAllJoins,
    refresh: refreshAllJoins,
  } = useInfiniteScroll<IJoin>(
    useGetJoinsQuery,
    {
      auctionBet: activeBet?.id,
      page: 0,
      size: 5,
      executionType: 'FREE',
      periodFilter: [
        {
          period:
            mode === FreegameMode.short ? FreegameBetDuration.short : FreegameBetDuration.long,
          condition: 'EQUALS',
        },
      ],
      sort: {
        direction: 'DESC',
        property: 'createdDate',
      },
    },
    {
      skip: !activeBet,
    },
  );

  const changeMode = (freegamemode: FreegameMode) => {
    Cookies.set('activeMode', freegamemode, {
      expires: 30,
    });
    refreshMyJoins();
    refreshAllJoins();
    dispatch(setMode(freegamemode));
  };

  const handleBetChange = useCallback(
    (value: string) => {
      dispatch(setBetValue(value));
    },
    [dispatch],
  );

  const loadBets = useCallback(() => {
    getBets({
      page: 0,
      size: 1,
      statusList: { list: [BetStatus.CREATED] },
      sort: { direction: 'DESC', property: 'createdDate' },
      executionType: 'FREE',
      periodFilter: [
        {
          period:
            mode === FreegameMode.short ? FreegameBetDuration.short : FreegameBetDuration.long,
          condition: 'EQUALS',
        },
      ],
    });
  }, [getBets, mode]);

  const refreshBets = () => {
    if (refreshTimer.current) {
      clearTimeout(refreshTimer.current);
    }
    loadBets();
    refreshMyJoins();
    refreshAllJoins();
  };

  const onPlaceBet = () => {
    if (activeBet) {
      placeBet({
        targetValue: betValue.toString(),
        betId: activeBet.id,
      })
        .unwrap()
        .then(() => {
          if (!document.hidden) {
            setOpenBottomSheet(false);
            toast(<ToastContent text={willBeAccepted} title={betsAccepted} />, {
              pauseOnFocusLoss: false,
            });
          }
        })
        .catch(() => {
          if (!document.hidden) {
            toast(<ToastContent text={errorOccured} title={errorText} />);
          }
        });
    }
  };

  useEffect(() => {
    loadBets();
  }, [loadBets]);

  useEffect(() => {
    if (betStatus === FreegameBetStatus.closed) {
      toast(
        <ToastContent
          button={
            <Button
              className={classNames(s.refreshButton, s[theme])}
              color="white"
              onClick={refreshBets}
              size="sm">
              {updateText}
            </Button>
          }
          text={updatePageText}
          title={dealDone}
        />,
        { autoClose: 15000, pauseOnFocusLoss: false, pauseOnHover: false },
      );

      refreshTimer.current = setTimeout(() => {
        refreshBets();
      }, 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betStatus]);

  useEffect(() => {
    if (bets && bets?.result.length > 0) {
      const [bet] = bets.result;
      dispatch(setActiveBet(bet));
    }
  }, [bets, dispatch]);

  useEffect(() => {
    if (mode === FreegameMode.long && userData?.username && activeBet) {
      getPersonalJoins({
        page: 0,
        size: 10,
        auctionBet: activeBet?.id,
        client: userData.username,
        executionType: 'FREE',
        periodFilter: [
          {
            period: FreegameBetDuration.long,
            condition: 'EQUALS',
          },
        ],
        sort: {
          direction: 'DESC',
          property: 'createdDate',
        },
        statusList: {
          list: [JoinStatus.JOINED, JoinStatus.LOST, JoinStatus.WON, JoinStatus.PRIZE_TAKEN],
        },
      });
    }
  }, [getPersonalJoins, activeBet, userData, bets, mode]);

  const updateLeader = useCallback(async () => {
    if (activeBet?.id) {
      try {
        const data = await getLeader({
          id: activeBet.id,
          value: currentRateRef.current,
        }).unwrap();
        setLeader(data);
      } catch (error) {
        setLeader(null);
        throw error;
      }
    }
  }, [activeBet, getLeader]);

  useEffect(() => {
    let updateLeaderTimer: null | NodeJS.Timer = null;

    if (activeBet && betStatus !== FreegameBetStatus.open) {
      if (updateLeaderTimer) {
        clearInterval(updateLeaderTimer);
      }

      updateLeaderTimer = setInterval(updateLeader, 2000);
    }

    return () => {
      if (updateLeaderTimer) {
        clearInterval(updateLeaderTimer);
      }
    };
  }, [activeBet, betStatus, updateLeader, getLeader]);

  useEffect(() => {
    if (currentRate && betStatus !== FreegameBetStatus.open) {
      currentRateRef.current = currentRate;
      if (activeBet && currentRate && !leader && !isLeaderLoaded.current) {
        isLeaderLoaded.current = true;
        updateLeader();
      }
    } else {
      setLeader(null);
    }
  }, [currentRate, activeBet, leader, updateLeader, betStatus, getLeader]);

  useEffect(() => {
    setLeader(null);
    if (betStatus !== FreegameBetStatus.open) {
      updateLeader();
    }
  }, [activeBet, updateLeader, betStatus]);

  const formattedAllJoins = useMemo(
    () =>
      allJoins
        ? mapJoinsToTable(allJoins, betStatus, leader, userData?.username, currentRate, lang)
        : [],
    [allJoins, betStatus, leader, userData?.username, currentRate, lang],
  );

  const activeTabs = [lastBets, myBets].filter((tab) => {
    if (!userData && tab === myBets) {
      return false;
    }
    return true;
  });
  const formattedMyJoins = useMemo(
    () =>
      myJoins
        ? myJoins.reduce((acc, join) => {
            const formattedDate = formatDate(join.createdDate, 'D MMMM, HH:mm', lang);
            acc.push([join.client, `$${join.targetValue}`, formattedDate]);
            return acc;
          }, [] as TableTd[][])
        : [],
    [myJoins, lang],
  );

  let leftAttempts: number | null = null;

  if (!isPersonalJoinsFetching) {
    if (personalJoins) {
      leftAttempts = TOTAL_ATTEMPTS - personalJoins.result.length;
    } else {
      leftAttempts = TOTAL_ATTEMPTS;
    }
  } else {
    leftAttempts = null;
  }

  const isRanoutOfAttempts = !leftAttempts || leftAttempts <= 0;

  const predictPrice = useTranslate('lottery.predict-price');
  const predictOnDate = useTranslate('options.predict-on-date');

  const renderPlaceYourBet = () => (
    <PlaceYourBet
      accordionOff
      attempts={mode === FreegameMode.long ? leftAttempts : undefined}
      betDisabled={
        !betValue ||
        betStatus !== FreegameBetStatus.open ||
        (mode === FreegameMode.long && isRanoutOfAttempts)
      }
      betValue={betValue}
      className={s.rightBlockItem}
      coefficient="10 FreeBet"
      expirationDate={activeBet?.expirationDate}
      inputIcon={<BtcIcon />}
      isAuthenticated={keycloak.authenticated}
      isLoading={isPlacingBet}
      labelRangeSlider={labelRangeSlider}
      lockDate={activeBet?.lockDate}
      maxPrize={
        mode === FreegameMode.long ? '100 USDT' : `${activeBet?.totalPool.toString()} FreeBet`
      }
      now={now || dayjs().valueOf()}
      onBetChange={handleBetChange}
      onClickBet={onPlaceBet}
      onLogin={() => keycloak.login()}
      rate={currentRate}
      rateTitle={<Translated id="freegame.rateTitle" />}
      theme={theme}
      title={makeBets}
      tooltipCommission="tooltip"
      tooltipDealLabel={mode === FreegameMode.short && yourBet}
      tooltipPrizeLabel={tooltipPrizeLabel}
    />
  );

  return (
    <Layout>
      <div className={s.tabs}>
        <div className={s.buttonWrapper}>
          <Button
            className={s.tabButton}
            color={mode === FreegameMode.long ? 'yellow' : 'black-yellow'}
            onClick={() => changeMode(FreegameMode.long)}
            size="mmd"
            theme={theme}
            variant="contained">
            <Translated id="freegame.daily-prize" />
          </Button>
          <div className={s.badgeItem}>
            <CrownIcon />
          </div>
        </div>
        <div className={s.buttonWrapper}>
          <Button
            className={s.tabButton}
            color={mode === FreegameMode.short ? 'yellow' : 'black-yellow'}
            onClick={() => changeMode(FreegameMode.short)}
            size="mmd"
            theme={theme}
            variant="contained">
            <Translated id="freegame.five-minute" />
          </Button>
          <div className={s.badgeItem}>
            <Badge badgeTitle="New" badgeVariant="new" />
          </div>
        </div>
      </div>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div>
            {activeBet && (
              <BetStatusInfo
                expirationDate={activeBet?.expirationDate}
                lockDate={activeBet.lockDate}
                now={now || dayjs().valueOf()}
                onExpire={loadBets}
                theme={theme}
              />
            )}
          </div>
          {activeBet && (
            <div className={s.questionBlock}>
              <QuestionsBlock
                smallBlock
                subTitle={
                  mode === FreegameMode.short
                    ? predictPrice
                    : `${predictOnDate} ${formatDate(
                        activeBet.expirationDate,
                        'D MMMM YYYY HH:mm',
                        lang,
                      )}`
                }
                theme={theme}
                title={
                  mode === FreegameMode.short
                    ? `В ${formatDate(activeBet.expirationDate, 'HH:mm, D MMMM YYYY', lang)}`
                    : undefined
                }
              />
            </div>
          )}
          <FreegameChart />
          <div className={s.tableTabs}>
            <Tabs tabsName={activeTabs}>
              <JoinTable
                hasMore={hasMoreAllJoins}
                loadMore={loadMoreAllJoins}
                tableRows={formattedAllJoins}
              />
              <JoinTable
                hasMore={hasMoreMyJoins}
                loadMore={loadMoreMyJoins}
                tableRows={formattedMyJoins}
              />
            </Tabs>
          </div>
          <div className={s.strTabsBlock}>
            <Tabs
              tabsName={[
                <Translated id="app.deal-details" />,
                <Translated id="freegame.what-is" />,
              ]}>
              <StringTabContent str={<Translated id="freegame.predict-btc" />} theme={theme} />
              <StringTabContent str={<Translated id="freegame.desc" />} theme={theme} />
            </Tabs>
          </div>
        </div>
        <div className={s.rightBlock}>{renderPlaceYourBet()}</div>
      </div>
      {openBottomSheet ? (
        <Modal open={openBottomSheet} setOpen={handleOpenBottomSheet} theme={theme}>
          {renderPlaceYourBet()}
        </Modal>
      ) : (
        isLaptop &&
        (keycloak.authenticated ? (
          <Button
            color="secondary"
            disabled={betStatus !== FreegameBetStatus.open}
            makeBet
            onClick={handleOpenBottomSheet}
            size="xl">
            {makeBets}
          </Button>
        ) : (
          <Button color="primary" makeBet onClick={() => keycloak.login()} size="xl" theme={theme}>
            {signup}
          </Button>
        ))
      )}

      <Toast className={s.betToast} />
    </Layout>
  );
};

export default Freegame;
