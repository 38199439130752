import { ToastContainer } from 'react-toastify';

import { CloseIcon } from 'components/Icons';

import s from './Toast.module.scss';

interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}

interface ToastProps {
  className?: string;
}

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={closeToast} type="button">
    <CloseIcon />
  </button>
);

const Toast = ({ className }: ToastProps) => (
  <div>
    <ToastContainer
      autoClose={5000}
      className={className}
      closeButton={CloseButton}
      closeOnClick
      draggable={false}
      hideProgressBar
      limit={3}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      position="bottom-right"
      rtl={false}
      theme="light"
      toastClassName={s.toast}
    />
  </div>
);

export default Toast;
