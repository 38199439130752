import { configureStore } from '@reduxjs/toolkit';

import { services, servicesMiddleware } from './services';
import appReducer from './slices/appSlice';
import freegameReducer from './slices/freegameSlice';
import modalsReducer from './slices/modalsSlice';
import userReducer from './slices/userSlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  reducer: {
    ...services,
    freegame: freegameReducer,
    modals: modalsReducer,
    user: userReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const additionalMiddleware = [...servicesMiddleware];

    return getDefaultMiddleware().concat(additionalMiddleware);
  },
});
