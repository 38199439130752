/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';
import { Children } from 'types/children';

import s from './Tabs.module.scss';

interface TabsProps {
  children: Children;
  tabsName: string[] | Children[];
  theme?: ThemeType;
  isDivider?: boolean;
  className?: string;
  pageTabsToChangeStyles?: boolean;
  onTabClick?: (tabNumber: number) => void;
}

const Tabs = ({
  children,
  pageTabsToChangeStyles = false,
  className,
  tabsName = ['name'],
  theme = 'default',
  isDivider = false,
  onTabClick,
}: TabsProps) => {
  const [val, setVal] = useState(0);

  const handleShowTab = (tabNum: number) => {
    setVal(tabNum);

    // TODO: костыль, нужен рефакторинг компонента для отвязки от индексов
    if (onTabClick) onTabClick(tabNum);
  };

  const isArrayChildren = Array.isArray(children);

  return (
    <div className={classNames(s.wrapper, s[theme])}>
      <div
        className={classNames(s.tabsBlock, className, {
          [s.pageStyles]: pageTabsToChangeStyles,
        })}>
        {tabsName.map((tabName, index) => (
          <div
            className={classNames(
              s.cursor,
              index === val && !pageTabsToChangeStyles && s.target,
              index === val && pageTabsToChangeStyles && s.targetPageStyle,
            )}
            key={index}
            onClick={() => handleShowTab(index)}>
            {tabName}
          </div>
        ))}
      </div>
      {isDivider && <div className={s.divider} />}
      <div>{isArrayChildren ? children[val] : children}</div>
    </div>
  );
};

export default Tabs;
