import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const ChevronRight = ({ height = '9', width = '7', color = '#101319', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 7 9"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.625 0.75L5.375 4.5L1.625 8.25"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default ChevronRight;
