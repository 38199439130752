import React, { useEffect } from 'react';

import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate.hook';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { Modals, closeModal } from 'store/slices/modalsSlice';

import { Children } from 'types/children';

import {
  changeTheme,
  setCookiesNotification,
  setOpenSideBar,
} from '../../../store/slices/appSlice';
import useMediaQuery from '../../../theme/useMediaQuery';
import ForgotPassFormModal from '../Auth/ForgotPassFormModal';
import LoginFormModal from '../Auth/LoginFormModal';
import RegisterFormModal from '../Auth/RegisterFormModal';
import Header from '../Header';
import Notification from '../Notification';
import SideBar from '../SideBar';
import { linkBaseUrl } from '../SideBar/SideBar';
import Translated from '../Translated';
import s from './Layout.module.scss';

interface LayoutProps {
  children?: Children;
}

const finMenuLinks = [
  {
    id: 1,
    link: `${linkBaseUrl}/bets/crypto`,
    text: <Translated id="sidebar.finance-deals.crypto" />,
  },
  {
    id: 2,
    link: `${linkBaseUrl}/bets/stock`,
    text: <Translated id="sidebar.finance-deals.stocks" />,
  },
  {
    id: 3,
    link: `${linkBaseUrl}/bets/bond`,
    text: <Translated id="sidebar.finance-deals.bonds" />,
  },
  {
    id: 4,
    link: `${linkBaseUrl}/bets/currency`,
    text: <Translated id="sidebar.finance-deals.currency" />,
  },
  {
    id: 5,
    link: `${linkBaseUrl}/bets/metals`,
    text: <Translated id="sidebar.finance-deals.metals" />,
  },
  {
    id: 6,
    link: `${linkBaseUrl}/bets/products`,
    text: <Translated id="sidebar.finance-deals.product" />,
  },
];

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state: RootState) => state.app);
  const isOpenSideBar = useSelector((state: RootState) => state.app.isOpenSideBar);
  const cookiesNotification = useSelector((state: RootState) => state.app.cookiesNotification);
  const { activeModal } = useSelector((state: RootState) => state.modals);

  const navigate = useNavigate();
  const isLaptop = useMediaQuery(1024);

  const handleOpenSideBar = (status: boolean) => {
    dispatch(setOpenSideBar(status));
  };

  const handleChangeTheme = () => {
    if (theme === 'default') {
      dispatch(changeTheme('dark'));
      Cookies.set('freegameTheme', 'dark', {
        expires: 1000,
      });
    } else {
      dispatch(changeTheme('default'));
      Cookies.set('freegameTheme', 'default', {
        expires: 1000,
      });
    }
  };

  const handleFreegameButton = () => navigate('/');
  const contentClasses = classNames(s.content, s[theme], isOpenSideBar && s[`content-open`]);

  useEffect(() => {
    if (isLaptop) {
      dispatch(setOpenSideBar(false));
    } else {
      dispatch(setOpenSideBar(true));
    }
  }, [dispatch, isLaptop]);

  const cookiesNotificationText = useTranslate('notification.we-use-cookie');
  const cookiesNotificationOk = useTranslate('notification.cookie-ok');
  const cookiesNotificationCollectionText = useTranslate('notification.collection-cookie');
  return (
    <div className={s.layout} id="layout">
      <Header
        changeTheme={handleChangeTheme}
        isOpenSideBar={isOpenSideBar}
        setOpenSideBar={handleOpenSideBar}
        theme={theme}
      />
      <div className={`${s.wrapper} ${s[theme]}`}>
        {isOpenSideBar ? (
          <SideBar
            financeMenuLinks={finMenuLinks}
            freegameBtnFunction={handleFreegameButton}
            theme={theme}
          />
        ) : null}
        {!(isLaptop && isOpenSideBar) ? <div className={contentClasses}>{children}</div> : null}
        {activeModal === Modals.login && (
          <LoginFormModal isOpen setOpen={() => dispatch(closeModal())} theme={theme} />
        )}
        {activeModal === Modals.register && (
          <RegisterFormModal isOpen setOpen={() => dispatch(closeModal())} theme={theme} />
        )}
        {activeModal === Modals.forgotPass && (
          <ForgotPassFormModal isOpen setOpen={() => dispatch(closeModal())} theme={theme} />
        )}
      </div>
      {!cookiesNotification && (
        <Notification
          buttonFunc={() => {
            dispatch(setCookiesNotification(true));
          }}
          handleClose={() => {
            dispatch(setCookiesNotification(true));
          }}
          subTitle={cookiesNotificationText}
          theme={theme}
          title={cookiesNotificationCollectionText}
          titleButton={cookiesNotificationOk}
        />
      )}
    </div>
  );
};

export default Layout;
