import React from 'react';

import { IconsTypes } from 'types/baseTypes';

const WalletIcon = ({ width = '26', height = '27', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 27H5a5 5 0 0 1-5-5V8a1 1 0 0 1 1-1h20a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5ZM2 9v13a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V12a3 3 0 0 0-3-3H2Z"
      fill="#0075FF"
    />
    <path
      d="M23 9a1 1 0 0 1-1-1V5.25a2.33 2.33 0 0 0-.78-1.87 1.94 1.94 0 0 0-1.67-.32L2.78 6.87a1 1 0 0 0-.78 1 1 1 0 0 1-2 0 3 3 0 0 1 2.33-2.95l16.78-3.81a3.9 3.9 0 0 1 3.36.71A4.34 4.34 0 0 1 24 5.25V8a1 1 0 0 1-1 1ZM25 21h-7a4 4 0 1 1 0-8h7a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1Zm-7-6a2 2 0 0 0 0 4h6v-4h-6Z"
      fill="#0075FF"
    />
  </svg>
);

export default WalletIcon;
