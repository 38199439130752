import React from 'react';
import s from './Switcher.module.scss';

interface SwitcherProps {
  onChange: () => void;
  value: boolean;
}

const Switcher: React.FC<SwitcherProps> = ({ onChange, value }) => (
  <label className={s['toggle-control']}>
    <input checked={value} onChange={onChange} type="checkbox" />
    <span className={s.control} />
  </label>
);

export default Switcher;
