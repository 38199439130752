import { forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { WebsocketContext } from 'context/websocket';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useTranslate from 'hooks/useTranslate.hook';
import { ColorType, createChart } from 'lightweight-charts';

import BinanceIcon from 'components/Icons/BinanceIcon';

import ChartHeader from './chart_header';
import s from './chart1.module.scss';
import {themesData} from './consts'

dayjs.extend(utc);


const Chart1 = forwardRef(
  (
    props: {
      theme: 'default' | 'dark';
      colors: {
        backgroundColor?: 'white' | undefined;
        lineColor?: '#2962FF' | undefined;
        textColor?: 'black' | undefined;
        areaTopColor?: '#2962FF' | undefined;
        areaBottomColor?: 'rgba(41, 98, 255, 0.28)' | undefined;
      };
      type: string | undefined;
      currentRate: string;
      setCurrentRate: (rate: string) => void;
    },
    ref: any,
  ) => {
    const {
      colors: {
        backgroundColor = 'white',
        lineColor = '#2962FF',
        textColor = 'black',
        areaTopColor = '#2962FF',
        areaBottomColor = 'rgba(41, 98, 255, 0.28)',
      },
      theme = 'default',
      type,
      currentRate,
      setCurrentRate,
    } = props;
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<any>(null);
    const { ws, ready } = useContext(WebsocketContext);
    const [direction, setDirection] = useState('up');
    const prev = useRef(0);
    function timeToLocal(originalTime: number) {
      const d = new Date(originalTime * 1000);
      return (
        Date.UTC(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes(),
          d.getSeconds(),
          d.getMilliseconds(),
        ) / 1000
      );
    }

    const [timeFrame, setTimeFrame] = useState<number | string>(1);
    const changeTimeFrame = useCallback((val: number | string) => {
      setTimeFrame(val);
    }, []);

    const onMessage = (event: MessageEvent) => {
      const parsed = JSON.parse(event.data);
      if (type && parsed && parsed[type]) {
        parsed[type] = parsed[type].map((item: any) => ({
          ...item,
          time: timeToLocal(item.time),
        }));
        ref.current.setData(parsed[type]);
        const cur = parsed[type][parsed[type].length - 1];

        if (cur.close !== prev.current) {
          setDirection(cur.close > prev.current ? 'up' : 'down');
        }
        prev.current = cur.close;
        setCurrentRate(cur.close);
      }
    };

    useEffect(() => {
      if (ready && ws && type) {
        ws.send(`subscribe__${type}__${timeFrame}`);
        ws.addEventListener('message', onMessage);
      }

      return () => {
        if (ws && ready) {
          ws.send(`unsubscribe__${type}__${timeFrame}`);
          ws.removeEventListener('message', onMessage);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, ws, ref, type, timeFrame]);

    useEffect(() => {
      chartRef.current?.applyOptions(themesData[theme].chart);
      ref.current?.applyOptions(themesData[theme].series);
    }, [ref, chartRef, theme]);

    useEffect(() => {
      // @typescript-eslint/ban-ts-comment
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: backgroundColor },
          textColor,
        },
        timeScale: {
          rightOffset: 1,
          barSpacing: 10,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: true,
          visible: true,
          timeVisible: true,
          secondsVisible: true,
        },
        width: 600,
        height: 300,
      });

      chartRef.current = chart;
      const timeScale = chart.timeScale();
      timeScale.fitContent();

      const handleResize = () => {
        chart.applyOptions({ width: chartContainerRef.current?.clientWidth });
      };

      // eslint-disable-next-line no-param-reassign
      ref.current = chart.addCandlestickSeries();
      ref.current.setData([]);
      window.addEventListener('resize', handleResize);
      handleResize();

      chart.applyOptions(themesData[theme].chart);
      ref.current.applyOptions(themesData[theme].series);

      return () => {
        window.removeEventListener('resize', handleResize);

        chart.remove();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, ref, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);

    const dataSource = useTranslate('options.data-source');
    return (
      <div className={classNames(s.chart, s[theme])}>
        <ChartHeader current={currentRate}   direction={direction} onChange={changeTimeFrame} />
        <div ref={chartContainerRef} />
        <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
          <span style={{ opacity: 0.8, fontSize: 12, marginRight: 10 }}>{dataSource}</span>
          <a href="https://www.binance.com/ru/trade/BTC_USDT" style={{ display: 'flex' }}>
            <BinanceIcon />
          </a>
        </div>
      </div>
    );
  },
);

export default Chart1;
