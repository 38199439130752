import React from 'react';

import s from './RadioButton.module.scss';

interface RadioButtonProps {
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  theme?: 'dark' | 'default';
  name?: string;
  value?: string;
}

const RadioButton = ({ checked, onChange, theme, name = 'radio', value }: RadioButtonProps) => {
  const isDarkTheme = theme === 'dark';
  return (
    <label className={`${s.radio} ${isDarkTheme ? s.dark : ''}`}>
      <input checked={checked} name={name} onChange={onChange} type="radio" value={value} />
      <span />
    </label>
  );
};

export default RadioButton;
