import en from 'lang/en';
import ru from 'lang/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const useTranslate = (id: keyof typeof en) => {
  const { lang } = useSelector((state: RootState) => state.app);

  if (lang === 'Ru') return ru[id];
  return en[id];
};
export default useTranslate;
