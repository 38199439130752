export enum BetStatus {
  CREATED = 'CREATED',
  PENDING_CLOSE = 'PENDING_CLOSE',
  CLOSED = 'CLOSED',
  SKIPPED_CLOSING = 'SKIPPED_CLOSING',
  FAILED = 'FAILED',
}

export enum JoinStatus {
  JOINED = 'JOINED',
  CANCELED = 'CANCELED',
  WON = 'WON',
  PRIZE_TAKEN = 'PRIZE_TAKEN',
  LOST = 'LOST',
  REFUNDED = 'REFUNDED',
}
