import { useEffect } from 'react';

import { useIMask } from 'react-imask';

import TextField, { TextFieldProps } from '../TextField/TextField';

interface BetInputProps extends Omit<TextFieldProps, 'setValue'> {
  setValue: (value: string) => void;
}

const BetInput = (props: BetInputProps) => {
  const { setValue, value } = props;
  const {
    ref,
    setValue: setMaskedValue,
    value: maskedValue,
  } = useIMask({
    mask: '00000.00',
  });

  useEffect(() => {
    setValue(maskedValue);
  }, [maskedValue, setValue]);

  return (
    <TextField
      {...props}
      inputRef={ref}
      setValue={(e) => {
        setMaskedValue(e.target.value);
      }}
      value={value}
    />
  );
};

export default BetInput;
