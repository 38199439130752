import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | ${prevTitle}`;
    return () => {
      document.title = prevTitle;
    };
  });
}
