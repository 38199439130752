import React from 'react';

type FinanceIconProps = {
  size?: string;
  className?: string;
  color?: string;
};

const FinanceIcon = ({ size = '25', className, color = '#0075FF' }: FinanceIconProps) => (
  <svg
    className={className}
    fill="none"
    height={size}
    viewBox="0 0 25 25"
    width={size}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_531_594)">
      <path
        d="M16.9751 19.7981C16.5449 20.7426 15.9424 21.6298 15.1678 22.4026C11.6967 25.8658 6.07436 25.8658 2.60335 22.4026C-0.867662 18.9395 -0.867764 13.33 2.60335 9.86696C3.37792 9.09416 4.26714 8.49324 5.21383 8.06384C4.98431 11.1263 6.04562 14.3031 8.39798 16.6499C10.7217 18.9681 13.877 20.0271 16.9751 19.7981Z"
        fill={color}
        opacity="0.3"
      />
      <path
        d="M22.3978 15.133C18.9266 18.5962 13.3043 18.5962 9.83328 15.133C6.36227 11.6698 6.36216 6.06038 9.83328 2.59733C13.3044 -0.865725 18.9267 -0.865827 22.3978 2.59733C25.8689 6.03171 25.8689 11.6413 22.3978 15.133ZM17.8081 8.29276C17.3205 8.03522 16.8328 7.83479 16.3164 7.63454C16.0294 7.52015 15.7427 7.37701 15.5132 7.17657C15.0255 6.7759 15.1116 6.17476 15.6852 5.91723C15.8573 5.85993 16.0008 5.83139 16.1729 5.80284C16.8326 5.77429 17.4637 5.88868 18.0662 6.17496C18.3532 6.3181 18.4678 6.26081 18.5538 5.97452C18.6685 5.65969 18.7547 5.31631 18.8408 5.00148C18.8982 4.7725 18.8122 4.62936 18.6113 4.54352C18.2383 4.37182 17.8367 4.25723 17.4351 4.20013C16.89 4.11429 16.89 4.11429 16.89 3.57049C16.89 2.79769 16.89 2.79769 16.1154 2.79769H15.7713C15.4271 2.79769 15.3411 2.88353 15.3411 3.22691V3.71343C15.3411 4.19995 15.3411 4.1714 14.882 4.34308C13.7633 4.74375 13.1036 5.48778 13.0175 6.69004C12.9601 7.74894 13.5052 8.46444 14.3944 9.00823C14.9395 9.32306 15.5418 9.52329 16.1157 9.78103C16.3452 9.86687 16.5459 9.98147 16.7468 10.1532C17.2919 10.6111 17.2058 11.3552 16.5459 11.6415C16.2017 11.7846 15.8287 11.8419 15.4558 11.7846C14.8821 11.6988 14.3084 11.5556 13.792 11.2981C13.4764 11.1264 13.3904 11.1837 13.2757 11.5271C13.1897 11.8134 13.1036 12.0994 13.0176 12.3857C12.9029 12.7864 12.9602 12.8722 13.3332 13.0439C13.8208 13.2729 14.3084 13.3873 14.8535 13.4732C15.2551 13.5305 15.2837 13.559 15.2837 13.9882V14.5606C15.2837 14.7896 15.3984 14.9327 15.6567 14.9327H16.5173C16.7469 14.9327 16.8615 14.7896 16.8615 14.5606V13.7878C16.8615 13.5302 16.9762 13.3871 17.2345 13.3298C17.837 13.1581 18.3246 12.8433 18.7262 12.3853C19.7301 11.0402 19.3285 9.12264 17.8081 8.29276Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_531_594">
        <rect fill="white" height="25" width="25" />
      </clipPath>
    </defs>
  </svg>
);

export default FinanceIcon;
