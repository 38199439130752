import React, { createContext, useEffect, useRef, useState } from 'react';

interface WebSocketContext {
  ws: null | WebSocket;
  ready: boolean;
}

export const WebsocketContext = createContext<WebSocketContext>({
  ws: null,
  ready: false,
});

type WebsocketProviderProps = {
  children?: React.ReactNode;
};

export const WebsocketProvider: React.FC<WebsocketProviderProps> = ({ children }) => {
  const ws = useRef<null | WebSocket>(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL as string);

    websocket.onopen = () => setReady(true);
    websocket.onclose = () => setReady(false);

    ws.current = websocket;
  }, [ws]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <WebsocketContext.Provider value={{ ws: ws.current, ready }}>
      {children}
    </WebsocketContext.Provider>
  );
};
