import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useMediaQuery from 'theme/useMediaQuery';

import Layout from 'components/Containers/Layout';
import PortfolioBetHistory from 'components/Containers/PortfolioBetHistory/PortfolioBetHistory';
import PortfolioTransactionHistory from 'components/Containers/PortfolioTransactionHistory/PortfolioTransactionHistory';
import Translated from 'components/Containers/Translated';
import Tabs from 'components/Simple/Tabs';
import Typography from 'components/Simple/Typography';

import s from './portfolio.module.scss';

const Portfolio = () => {
  const theme = useSelector((state: RootState) => state.app.theme);
  const isLaptop = useMediaQuery(1240);
  return (
    <Layout>
      {isLaptop ? (
        <Tabs
          className={s.tabsWrapper}
          pageTabsToChangeStyles
          tabsName={[
            <Typography variant="h1">
              <Translated id="portfolio.my-deals" />
            </Typography>,
            <Typography variant="h1">
              <Translated id="portfolio.transactions" />
            </Typography>,
          ]}
          theme={theme}>
          <PortfolioBetHistory />
          <PortfolioTransactionHistory />
        </Tabs>
      ) : (
        <div className={s.wrapper}>
          <PortfolioBetHistory />
          <PortfolioTransactionHistory />
        </div>
      )}
    </Layout>
  );
};

export default Portfolio;
