import React from 'react';
import { CryptoIconType } from 'types/baseTypes';

const TetherIcon = ({ size = '25' }: CryptoIconType) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 25 25"
    width={size}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_462_1446)">
      <path
        d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
        fill="#26A17B"
      />
      <path
        clipRule="evenodd"
        d="M14.0016 13.5812V13.5797C13.9156 13.5859 13.4727 13.6125 12.4844 13.6125C11.6953 13.6125 11.1398 13.5891 10.9445 13.5797V13.582C7.90703 13.4484 5.63984 12.9195 5.63984 12.2867C5.63984 11.6547 7.90703 11.1258 10.9445 10.9898V13.0555C11.143 13.0695 11.7117 13.1031 12.4977 13.1031C13.4406 13.1031 13.9133 13.0641 14.0016 13.0563V10.9914C17.0328 11.1266 19.2945 11.6555 19.2945 12.2867C19.2945 12.9195 17.0328 13.4469 14.0016 13.5812ZM14.0016 10.7766V8.92813H18.2312V6.10938H6.71484V8.92813H10.9445V10.7758C7.50703 10.9336 4.92188 11.6148 4.92188 12.4305C4.92188 13.2461 7.50703 13.9266 10.9445 14.0852V20.0086H14.0016V14.0836C17.4336 13.9258 20.0125 13.2453 20.0125 12.4305C20.0125 11.6156 17.4336 10.9352 14.0016 10.7766Z"
        fill="white"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_462_1446">
        <rect fill="white" height="25" width="25" />
      </clipPath>
    </defs>
  </svg>
);

export default TetherIcon;
