import en from 'lang/en';
import ru from 'lang/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type ITranslated = {
  id: keyof typeof en;
};
const Translated = ({ id }: ITranslated) => {
  const { lang } = useSelector((state: RootState) => state.app);
  if (lang === 'Ru') {
    return <span>{ru[id]}</span>;
  }
  return <span>{en[id]}</span>;
};

export default Translated;
