import React from 'react';

type FreegameIconProps = {
  width?: string;
  height?: string;
  className?: string;
  color?: string;
};

const FreegameIcon = ({
  width = '15',
  height = '21',
  className,
  color = '#0075FF',
}: FreegameIconProps) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 15 21"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 1C2.7 1 1.16667 5.33333 1 7.5V18.5L4.5 15L7.5 18.5L10.5 15L13.5 18.5V7C13.5 5 12.3 1 7.5 1Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path d="M3.5 7.5H4.5V10.5H3.5V7.5Z" fill={color} />
    <path d="M4.5 8.5H5.5V10.5H4.5V8.5Z" fill={color} />
    <path d="M7.5 7.5H8.5V10.5H7.5V7.5Z" fill={color} />
    <path d="M8.5 8.5H9.5V10.5H8.5V8.5Z" fill={color} />
  </svg>
);

export default FreegameIcon;
