import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const LockIcon = ({ height = '13', width = '11', color = '#FFF', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 11 13"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.98229 5.46206H9.13831V3.60779C9.13831 1.75631 7.50732 0.25 5.5007 0.25C3.49408 0.25 1.86311 1.7573 1.86311 3.60999V5.46206H1.02849C0.739297 5.46206 0.5 5.67392 0.5 5.94066V11.5431C0.5 12.2089 1.0921 12.75 1.81391 12.75H9.19687C9.91868 12.75 10.5014 12.2089 10.5014 11.5431V5.94066C10.5014 5.67392 10.2715 5.46206 9.98229 5.46206ZM2.90922 3.60999C2.90922 2.28991 4.07154 1.21594 5.5007 1.21594C6.92988 1.21594 8.0922 2.28891 8.0922 3.60779V5.46206H2.90922V3.60999ZM9.45531 11.5431C9.45531 11.6763 9.34123 11.7851 9.19687 11.7851H1.81391C1.66955 11.7851 1.54611 11.6763 1.54611 11.5431V6.42696H9.45531V11.5431Z"
      fill={color}
    />
    <path
      d="M5.50157 10.5542C5.79076 10.5542 6.02462 10.338 6.02462 10.0713V8.33257C6.02462 8.06584 5.79075 7.84961 5.50157 7.84961C5.21238 7.84961 4.97852 8.06584 4.97852 8.33257V10.0713C4.97852 10.338 5.21238 10.5542 5.50157 10.5542Z"
      fill={color}
    />
  </svg>
);

export default LockIcon;
