import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const RusFlagIcon = ({ width = '20', height = '15', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 20 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <rect fill="white" height="5" width="20" />
    <rect fill="#0023F5" height="5" width="20" y="5" />
    <rect fill="#E93222" height="5" width="20" y="10" />
  </svg>
);

export default RusFlagIcon;
