import React from 'react';

import { ThemeType } from 'types/baseTypes';

import s from './Label.module.scss';

interface LabelProps {
  leftIcon?: string | React.ReactNode;
  title?: string | React.ReactNode;
  rightElement?: string | React.ReactNode;
  nextElement?: boolean;
  className?: string;
  theme?: ThemeType;
  onClick?: () => void;
}

const Label = ({
  leftIcon,
  nextElement = false,
  onClick,
  title = '',
  rightElement,
  className = '',
  theme = 'default',
}: LabelProps) => (
  <>
    <div className={`${s.wrapper} ${className}`} onClick={() => onClick && onClick()}>
      {leftIcon ? <div className={s.iconBlock}>{leftIcon}</div> : null}
      <div className={s.textBlock}>{title}</div>
      <div className={s.rightBlock}>{rightElement}</div>
    </div>
    {nextElement ? <div className={`${s.divider} ${s[theme]}`} /> : null}
  </>
);

export default Label;
