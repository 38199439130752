import React from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import CurrencyPair from '../../Simple/CurrencyPair';
import PulseBulb from '../../Simple/PulseBulb';
import Typography from '../../Simple/Typography';
import s from './RowBet.module.scss';

export type PulseVariant = 'open' | 'accepted' | 'waiting' | 'closed' | 'canceled';

interface RowBetProps {
  theme?: ThemeType;
  inIcon?: React.ReactNode;
  outIcon?: React.ReactNode;
  className?: string;
  pulseVariant?: PulseVariant;
  currencyTitle?: string;
  currencySubTitle?: string;
  pulseTitle?: string;
  pulseSubtitle?: string;
  pulseTime?: string;
  generalTitle?: string;
  generalSubTitle?: string;
  doneTitle?: string;
  doneSubTitle?: string;
}

interface CeilProps {
  leftBlock?: React.ReactNode;
  title?: string;
  subTitle?: string;
  pulseTime?: string;
}

export const Ceil = ({ leftBlock, title = '', subTitle = '', pulseTime = '' }: CeilProps) => (
  <div className={s.ceil}>
    {leftBlock}
    <div className={s.centerText}>
      <Typography>{title}</Typography>
      <Typography color="grey" variant="subtitle">
        {subTitle}
      </Typography>
      {pulseTime && (
        <Typography color="grey" variant="subtitle">
          {pulseTime}
        </Typography>
      )}
    </div>
  </div>
);

const RowBet = ({
  className,
  theme = 'default',
  inIcon,
  outIcon,
  pulseVariant = 'open',
  pulseSubtitle = '',
  pulseTitle = '',
  pulseTime = '',
  currencyTitle = '',
  currencySubTitle = '',
  generalTitle = '',
  generalSubTitle = '',
  doneTitle = '',
  doneSubTitle = '',
}: RowBetProps) => (
  <div className={classNames(s.headRow, s[theme], className)}>
    <Ceil
      leftBlock={<CurrencyPair iconLeft={inIcon} iconRight={outIcon} theme={theme} />}
      subTitle={currencySubTitle}
      title={currencyTitle}
    />
    <div className={s.divider} />
    <Ceil
      leftBlock={<PulseBulb variant={pulseVariant} />}
      pulseTime={pulseTime}
      subTitle={pulseSubtitle}
      title={pulseTitle}
    />
    {(generalSubTitle || generalTitle) && (
      <>
        <div className={s.divider} />
        <Ceil subTitle={generalSubTitle} title={generalTitle} />
      </>
    )}
    {/* <div className={s.divider} />
    <Ceil subTitle={doneSubTitle} title={doneTitle} /> */}
  </div>
);

export default RowBet;
