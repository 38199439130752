import React from 'react';
import classNames from 'classnames';
import { ThemeType } from 'types/baseTypes';
import useColor from '../../../theme/useColor';
import { ChevronTopIcon } from '../../Icons';
import Typography from '../Typography';
import s from './Processing.module.scss';

interface ProcessingProps {
  theme?: ThemeType;
  dynamicStatus?: 'up' | 'down';
  count?: string | number;
  title?: string;
}

const Processing = ({
  theme = 'default',
  dynamicStatus = 'up',
  count = 0,
  title = '',
}: ProcessingProps) => {
  const { green500, red500 } = useColor();
  const getDynamicStatus = (status: string) => (status === 'up' ? 'up' : 'down');
  const isUp = dynamicStatus === 'up';

  const getCount = (num: string | number) => {
    if (num > 100) return 100;
    return num;
  };

  return (
    <div>
      <div className={s.header}>
        <div>
          <ChevronTopIcon
            className={classNames(!isUp && s.svgRed)}
            color={isUp ? green500 : red500}
          />
        </div>
        <div>
          <Typography color={theme === 'dark' ? 'white' : 'dark'}>{title}</Typography>
        </div>
        <Typography color={theme === 'dark' ? 'white' : 'dark'} variant="small">
          {count} %
        </Typography>
      </div>
      <div className={s.footerBlock}>
        <div className={classNames(s.canvas, s[theme])} />
        <div
          className={classNames(s.calculation, s[getDynamicStatus(dynamicStatus)])}
          style={{ width: `${getCount(count)}%` }}
        />
      </div>
    </div>
  );
};

export default Processing;
