import React from 'react';

import { IconsTypes } from 'types/baseTypes';

type CircleIconProps = {
  size?: string;
};

type IconProps = IconsTypes & CircleIconProps;

const CrownIcon = ({ size = '29', className }: IconProps) => (
  <svg
    className={className}
    fill="none"
    height={size}
    width={size}
    xmlns="http://www.w3.org/2000/svg">
    <rect fill="#474231" height={size} rx="14.5" width={size} />
    <g clipPath="url(#a)" fill="#E9D111">
      <path d="m7.885 10.14 1.827 7.94c.038.18.211.32.384.32h8.789c.192 0 .346-.14.384-.32l1.827-7.94c.039-.16-.135-.3-.27-.2l-3.345 2.26c-.077.06-.173.04-.231-.04l-2.615-3.04a.181.181 0 0 0-.27 0l-2.615 3.04a.199.199 0 0 1-.23.04L8.172 9.94c-.135-.1-.327.02-.288.2ZM8.5 8.68c0 .43-.336.78-.75.78S7 9.111 7 8.68c0-.43.336-.78.75-.78s.75.35.75.78ZM15.25 7.78c0 .43-.336.78-.75.78s-.75-.35-.75-.78c0-.43.336-.78.75-.78s.75.35.75.78ZM22 8.7c0 .43-.336.78-.75.78s-.75-.35-.75-.78c0-.43.336-.78.75-.78s.75.35.75.78ZM18.635 22c.211 0 .48-.18.48-.4v-1.8c0-.22-.269-.4-.48-.4h-8.27c-.211 0-.48.18-.48.4v1.8c0 .22.269.4.48.4h8.27Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h15v15H0z" fill="#fff" transform="translate(7 7)" />
      </clipPath>
    </defs>
  </svg>
);

export default CrownIcon;
