import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { History, Transaction } from 'types/balance';

const mapTransactionsToHistory = (data: Transaction[]) =>
  data.reduce((acc, transaction) => {
    const formattedDate = dayjs(`${transaction.date}Z`).locale('ru').format('D/MM/YY');
    const transactionByDate = acc.find((item) => item.date === formattedDate);

    if (transactionByDate) {
      transactionByDate.transactions.push(transaction);
      // sort by date desc
      transactionByDate.transactions = transactionByDate.transactions.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
    } else {
      acc.push({
        date: formattedDate,
        transactions: [transaction],
      });
    }

    return acc;
  }, [] as History[]);

export default mapTransactionsToHistory;
