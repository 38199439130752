import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useGetUserDataQuery } from 'store/services/apiService';

import ArrowIcon from 'components/Icons/ArrowIcon';
import WalletIcon from 'components/Icons/WalletIcon';
import { NavigateLinks, ThemeType } from 'types/baseTypes';

import freegameLogo from '../../../images/freegame-logo.svg';
import useColor from '../../../theme/useColor';
import useMediaQuery from '../../../theme/useMediaQuery';
import { BurgerIcon, CircleIcon, CloseIcon, CrumbsIcon } from '../../Icons';
import LogoIcon from '../../Icons/LogoIcon';
import Button from '../../Simple/Button';
import Label from '../../Simple/Label';
import Switcher from '../../Simple/Switcher';
import Typography from '../../Simple/Typography';
import Menu from '../Menu';
import NavigateBlock from '../NavigateBlock';
import { linkBaseUrl } from '../SideBar/SideBar';
import Translated from '../Translated';
import s from './Header.module.scss';

interface IsConnectWalletBlockProps {
  theme?: ThemeType;
  setOpenSideBar: (status: boolean) => void;
  isLaptop: boolean;
}

interface HeaderProps {
  theme?: ThemeType;
  setOpenSideBar: (status: boolean) => void;
  isOpenSideBar?: boolean;
  changeTheme: () => void;
  navigateLinks?: NavigateLinks[];
}

const IsConnectWalletBlock = ({ theme, setOpenSideBar, isLaptop }: IsConnectWalletBlockProps) => {
  const { keycloak } = useKeycloak();
  const handleLinkClick = () => {
    if (setOpenSideBar && isLaptop) setOpenSideBar(false);
  };
  return (
    <>
      <div className={classNames(s.menuLabel, s.hideOnTablet)}>
        <NavLink className={s.menuLink} onClick={handleLinkClick} to="/portfolio">
          <Label
            nextElement
            theme={theme}
            title={
              <Typography variant="title">
                <Translated id="header.burger.my-portfolio" />
              </Typography>
            }
          />
        </NavLink>
      </div>
      <div className={s.menuLabel}>
        <NavLink className={s.menuLink} onClick={handleLinkClick} to="/profile">
          <Label
            nextElement
            theme={theme}
            title={
              <Typography variant="title">
                <Translated id="header.burger.edit-profile" />
              </Typography>
            }
          />
        </NavLink>
      </div>
      <div className={s.menuLabel} onClick={() => keycloak.logout()}>
        <Label
          theme={theme}
          title={
            <Typography variant="title">
              <Translated id="header.burger.log-out" />
            </Typography>
          }
        />
      </div>
    </>
  );
};

const Header = ({
  theme = 'default',
  setOpenSideBar,
  isOpenSideBar,
  changeTheme,
  navigateLinks,
}: HeaderProps) => {
  const { lowDark, white, blue800, primary } = useColor();
  const isLaptop = useMediaQuery(1024);
  const { keycloak } = useKeycloak();
  const { balance } = useSelector((state: RootState) => state.user);
  const { data: userData } = useGetUserDataQuery(null, {
    skip: !keycloak.authenticated,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const handleAuth = () => {
    navigate('');
    keycloak.login();
  };

  const isDarkTheme = theme === 'dark';
  const getColor = (isDarkTh: boolean) => (isDarkTh ? white : lowDark);

  const balanceBlock = () => (
    <div className={s.connectLeft}>
      {balance && (
        <div className={s.balance}>
          <WalletIcon className={s.walletIcon} />
          <span>{balance} FreeBet</span>
        </div>
      )}
      <div className={s.connectBtn}>
        <div className={s[`circleIcon-${theme}`]}>
          <CircleIcon stopColor={isDarkTheme ? blue800 : primary} />
        </div>
        {userData?.username && (
          <div className={s.box}>
            <NavLink className={s.text} to="/portfolio">
              <div className={s.username} title={userData?.username}>
                {userData?.username}
              </div>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );

  const renderBackLink = () => {
    if (location.pathname === '/') {
      return (
        <a className={classNames(s.exitLink, s[theme])} href={linkBaseUrl}>
          <ArrowIcon className={s.exitArrowIcon} color={isDarkTheme ? 'white' : 'black'} />
          <Translated id="freegame.leave" />
        </a>
      );
    }
    return (
      <button
        className={classNames(s.exitLink, s[theme])}
        onClick={() => navigate(-1)}
        type="button">
        <ArrowIcon className={s.exitArrowIcon} color={isDarkTheme ? 'white' : 'black'} />
        <Translated id="freegame.back" />
      </button>
    );
  };

  return (
    <header>
      <div className={`${s.wrapper} ${s[theme]} `}>
        <div
          className={classNames(
            s.sideBlock,
            s[`sideBlock-${theme}`],
            `${s[`sideBlock-${theme}-${isOpenSideBar}`]}`,
          )}>
          <NavLink to="/">
            <div className={s.logoI}>
              <LogoIcon color={getColor(isDarkTheme)} />
              <img alt="logo" src={freegameLogo} />
            </div>
          </NavLink>
          {!isLaptop ? (
            <Button iconButton onClick={() => setOpenSideBar(!isOpenSideBar)}>
              <BurgerIcon color={getColor(isDarkTheme)} />
            </Button>
          ) : null}
        </div>
        <div className={classNames(s.navigateWrapper, s[`navigateWrapper-${theme}`])}>
          {renderBackLink()}
          {navigateLinks && <NavigateBlock navigateLinks={navigateLinks} />}
          <div className={s.connectBlock}>
            {keycloak.authenticated ? (
              balanceBlock()
            ) : (
              <div>
                <Button className={s.desktopAuthorizeButton} onClick={handleAuth} size="sm">
                  <Translated id="freegame.signup" />
                </Button>
              </div>
            )}

            <div className={s.rightIconBtn}>
              {!isLaptop ? (
                <Menu
                  anchor="bottom"
                  button={
                    <Button align="end" iconButton>
                      <CrumbsIcon color={getColor(isDarkTheme)} />
                    </Button>
                  }
                  theme={theme}>
                  <>
                    <div>
                      <div className={classNames(s.menuElement, s[`menuElementTheme-${theme}`])}>
                        <Label
                          nextElement={!!keycloak.authenticated}
                          rightElement={<Switcher onChange={changeTheme} value={isDarkTheme} />}
                          theme={theme}
                          title={
                            <Typography variant="title">
                              <Translated id="header.burger.dark-theme" />
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                    {keycloak.authenticated && (
                      <IsConnectWalletBlock
                        isLaptop={isLaptop}
                        setOpenSideBar={setOpenSideBar}
                        theme={theme}
                      />
                    )}
                  </>
                </Menu>
              ) : (
                <Button
                  iconButton
                  onClick={() => {
                    setOpenSideBar(!isOpenSideBar);
                  }}>
                  {!isOpenSideBar ? (
                    <BurgerIcon color={getColor(isDarkTheme)} />
                  ) : (
                    <CloseIcon color={getColor(isDarkTheme)} />
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isOpenSideBar ? (
        <div className={classNames(s.innerHeader, s[`innerHeader-${theme}`])}>
          {keycloak.authenticated ? (
            balanceBlock()
          ) : (
            <div>
              <Button onClick={handleAuth} size="sm">
                <Translated id="freegame.signup" />
              </Button>
            </div>
          )}
          <Menu
            anchor="bottom"
            button={
              <Button align="end" iconButton>
                <CrumbsIcon color={getColor(isDarkTheme)} />
              </Button>
            }
            theme={theme}>
            <div>
              <div className={classNames(s.menuElement, s[`menuElementTheme-${theme}`])}>
                <Label
                  nextElement={!!keycloak.authenticated}
                  rightElement={<Switcher onChange={changeTheme} value={isDarkTheme} />}
                  theme={theme}
                  title={
                    <Typography variant="title">
                      <Translated id="header.burger.dark-theme" />
                    </Typography>
                  }
                />
              </div>
            </div>
            {keycloak.authenticated ? (
              <IsConnectWalletBlock
                isLaptop={isLaptop}
                setOpenSideBar={setOpenSideBar}
                theme={theme}
              />
            ) : (
              <span />
            )}
          </Menu>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
