import { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import { ThemeType } from 'types/baseTypes';

import s from './Link.module.scss';

interface LinkProps {
  onClick?: () => void;
  title: ReactNode;
  to: string;
  theme: ThemeType;
}

const Link = ({ to, title, theme, onClick }: LinkProps) => (
  <li className={s.itemList}>
    <a className={classNames(s.link, s[theme])} href={to} onClick={onClick}>
      {title}
    </a>
  </li>
);

export default Link;
