import classNames from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Button from 'components/Simple/Button';
import TextField from 'components/Simple/TextField';
import { ThemeType } from 'types/baseTypes';

import Modal from '../Modal';
import s from './LoginFormModal.module.scss';

interface ForgotPassFormModalProps {
  isOpen: boolean;
  setOpen: () => void;
  theme: ThemeType;
}

const ForgotPassFormModal = ({ isOpen, setOpen, theme }: ForgotPassFormModalProps) => {
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, control } = methods;

  const onSubmit = () => {};

  return (
    <Modal open={isOpen} setOpen={setOpen} theme={theme}>
      <header className={classNames(s.header, s[theme])}>
        <h2 className={s.title}>Забыли пароль?</h2>
      </header>
      <FormProvider {...methods}>
        <form className={s.form}>
          <div className={s.field}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  label="Ваш e-mail"
                  placeholder=""
                  setValue={field.onChange}
                  theme={theme}
                  value={field.value}
                />
              )}
            />
          </div>
          <div>
            <Button
              className={s.submitButton}
              color="secondary"
              onClick={() => handleSubmit(onSubmit)()}
              size="xl">
              Восстановить пароль
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ForgotPassFormModal;
