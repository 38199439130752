import React from 'react';
import { IconsTypes } from 'types/baseTypes';

const BurgerIcon = ({ height = '15', width = '20', color = '#101319', className }: IconsTypes) => (
  <svg
    className={className}
    fill="none"
    height={height}
    viewBox="0 0 20 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0909 2.5H0.90914C0.584255 2.5 0.284306 2.30935 0.121753 1.99989C-0.0405842 1.69066 -0.0405842 1.30932 0.121753 1.00011C0.2843 0.690651 0.584244 0.5 0.90914 0.5H19.0909C19.4157 0.5 19.7157 0.690651 19.8782 1.00011C20.0406 1.30934 20.0406 1.69068 19.8782 1.99989C19.7157 2.30935 19.4158 2.5 19.0909 2.5Z"
      fill={color}
    />
    <path
      d="M19.0909 8.5H0.90914C0.584255 8.5 0.284306 8.30935 0.121753 7.99989C-0.0405842 7.69066 -0.0405842 7.30933 0.121753 7.00011C0.2843 6.69065 0.584244 6.5 0.90914 6.5H19.0909C19.4157 6.5 19.7157 6.69065 19.8782 7.00011C20.0406 7.30934 20.0406 7.69068 19.8782 7.99989C19.7157 8.30935 19.4158 8.5 19.0909 8.5Z"
      fill={color}
    />
    <path
      d="M10.0909 14.5H0.90914C0.584255 14.5 0.284306 14.3093 0.121753 13.9999C-0.0405842 13.6907 -0.0405842 13.3093 0.121753 13.0001C0.2843 12.6907 0.584244 12.5 0.90914 12.5H10.0909C10.4157 12.5 10.7157 12.6907 10.8782 13.0001C11.0406 13.3093 11.0406 13.6907 10.8782 13.9999C10.7157 14.3093 10.4158 14.5 10.0909 14.5Z"
      fill={color}
    />
  </svg>
);

export default BurgerIcon;
