import LoadingCircleIcon from 'components/Icons/LoadingCircleIcon';

import s from './Spinner.module.scss';

const Spinner = () => (
  <div className={s.loaderWrap}>
    <LoadingCircleIcon className={s.loader} />
  </div>
);

export default Spinner;
